import { PATENT_LINK } from '@/constants/links';

export const FAQMainPageList = [
  {
    question:
      'What is generative AI for customer support, and how can business benefit from it?',
    answer: (
      <div>
        <p>
          Generative AI for customer support is a suite of technologies that,
          when applied to the customer service domain, enhance performance and
          cost-efficiency. The term “generative AI” is related to the Artificial
          Intelligence’s ability to produce original content in a form of text,
          image, or voice message. The primary task of solutions for customer
          support built on generative AI technologies is routine task
          automation. Here is a few use cases on how AI handles this task:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              an AI chatbot provides response suggestions or ready-to-send
              answers to users’ queries;
            </p>
          </li>
          <li>
            <p>
              AI analyzes customers’ purchasing history, the content of
              correspondence and interactions with products and rervices
              provider; based on this data, AI tools for customer support share
              recommendations based on users’ preferences;
            </p>
          </li>
          <li>
            <p>
              AI collects data related to the relationships between a brand and
              its clients to summarize data and elicit business insights for
              future business developments.
            </p>
          </li>
        </ul>
        <p>
          Generative AI for customer support exists in various forms; however,
          the most widespread AI solution is AI-powered chatbots and assistants
          that communicate with customers in a human-like manner.
        </p>
        <p>
          AI chatbots and assistants integrate with all channels of
          communication, such as email, social media, live chats, interactive
          voice response (IVR) systems, and self-service portals. Such
          integration results in response time reduction, rate of resolved
          tickets increase, the reduction of cost per contact, and other
          enhancements to customer service-related metrics. 
        </p>
        <p>
          The final goal of AI integration to customer support  is the enhanced
          customer experience and a higher level of customer satisfaction.
        </p>
      </div>
    ),
  },
  {
    question:
      'How can your business benefit from CoSupport AI tools for customer support?',
    answer: (
      <div>
        <p>
          By CoSupport AI’s solutions implementation, a business benefits from
          all the capabilities that generative AI offers in customer support.
          These capabilities are as follows:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              response automation based on the innovative approaches that
              ensures answers precision, relevancy, and personalization;
            </p>
          </li>
          <li>
            <p>
              assistance for customer service agents with up to 80% automation,
              allowing human agents to curate response content;
            </p>
          </li>
          <li>
            <p>
              AI integration with all popular tools and across different
              channels, such as CRMs, email, website chats, and social media;
            </p>
          </li>
          <li>
            <p>
              AI application to analyzing data for more efficient
              decision-making and improvements in customer service domain and
              business in general.
            </p>
          </li>
        </ul>
        <p>
          CoSupport AI products’ capabilities entails the following benefits for
          businesses:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              24/7 support that excludes human errors, lack of motivation, and
              distractors impact;
            </p>
          </li>
          <li>
            <p>
              considerable saving of time and effort, along with less
              resource-demanding scaling up the customer service due to
              delegating the routine to AI assistants;
            </p>
          </li>
          <li>
            <p>
              Substantial reduction in budgets as a result of decreased
              expenditures on hiring staff and operational processes
              maintenance.
            </p>
          </li>
        </ul>
        <p>
          <a
            href="/articles/klarna-ai-wins-big"
            target="_blank"
            className="underline underline-offset-2 cursor-pointer"
          >
            Klarna’s case
          </a>{' '}
          is a good example of the business benefits achievable through
          generative AI implementation in customer support. Klarna achieved a
          reduction of response time from 11 minutes to 2 and the decrease in
          repeated queries by 25%. Its predicted rise in profits was announced
          as $40 million due to AI integration.
        </p>
      </div>
    ),
  },
  {
    question:
      'Why are CoSupport AI tools for customer support different from other solutions?',
    answer: (
      <div>
        <p>
          CoSupport AI solutions are based on what the team calls native AI.
          It’s the suite of distinct approaches that allows for building deeply
          custom products.
        </p>
        <p>
          Customization is treated not only in general terms, meaning that
          products are tailored to the needs of a particular company.
          Customization also implies that the opportunity to adjust solutions to
          a particular business are wider compared to the solutions that are
          based on the ready-to-use products, such as well-known LLMs. 
        </p>
        <p>
          CoSupport AI builds solutions, adjusting key AI models parameters and
          influencing the outcome directly. The cornerstones of outcomes are as
          follows:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>response speed;</p>
          </li>
          <li>
            <p>response precision;</p>
          </li>
          <li>
            <p>data security.</p>
          </li>
        </ul>
        <p>
          The core of the approach that aims at deep customization is an
          ensemble of AI models, including models trained from scratch for high
          performance in a customer support domain and solid security. 
        </p>
        <p>Here are four examples:</p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport AI uses the{' '}
              <a
                href={PATENT_LINK}
                target="_blank"
                rel="noreferrer"
                className="underline underline-offset-2 cursor-pointer"
              >
                patented in the U.S.
              </a>{' '}
              response acceleration architecture that allows one of the
              solutions, CoSupport Agent, to provide{' '}
              <a
                href="/articles/cosupport-ai-patent"
                target="_blank"
                className="underline underline-offset-2 cursor-pointer"
              >
                three times faster response
              </a>{' '}
              compared to GPT;
            </p>
          </li>
          <li>
            <p>
              the training data involved in a particular project never overlaps
              with the data related to other projects that provides close to
              zero risk of data leakage;
            </p>
          </li>
          <li>
            <p>
              Intelligent Embedding approach, based on the embedding that are
              performed by a dedicated model, lay the foundation for a high
              response precision;
            </p>
          </li>
          <li>
            <p>
              databases for back-end operations are organized in a way that
              provides close to zero risk of jailbreaking - the intrusion to the
              models’ “brains” for stealing sensitive data.
            </p>
          </li>
        </ul>
        <p>
          The CoSupport AI team has been working on distinct solutions
          development since [figure] year. Today, it invests all its experience
          and expertise in the creation of highly performative AI tools for
          customer support.
        </p>
      </div>
    ),
  },
  {
    question:
      'What do I need to implement AI for customer support solutions with CoSupport AI as a partner?',
    answer: (
      <div>
        <p>
          There aren’t any special requirements to start implementing AI in a
          business. However, it’s recommended to have a properly structured,
          comprehensive knowledge based for training the AI models. We may share
          with you the detailed recommendations on structuring your knowledge
          base for the most productive outcomes.
        </p>
        <p>
          For two of CoSupport AI’s solutions, CoSupport Agent and CoSupport
          Customer, we’ll ask you for access to the databases related to the
          processes involved in a project.
        </p>
        <p>
          CoSupport AI uses CoSupport AI Data Grid to keep your data safe - an
          approach to keeping and processing your data that minimizes the data
          loss and jailbreaking. All data is kept and processed on dedicated
          servers.
        </p>
      </div>
    ),
  },
  {
    question:
      'How much does it cost to implement AI for customer support solutions with CoSupport AI as a partner?',
    answer: (
      <div>
        <p>
          The approaches to the particular CoSupport AI’s solutions pricing
          policy are different due to the differences in solutions’
          architectures and required assets, including expertise. However, all
          approaches have the pricing policies transparency in common.
        </p>
        <p>
          Here are the basics of the pricing policy regarding key CoSupport AI
          products:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport Agent follows the scheme “fixed fee for one agent for
              one month.” In this way, the number of tokens (pieces of text such
              as syllables) often unpredictable, is excluded from the scheme for
              enhanced predictability. The fee is based on the number of
              responses provided with a stated limit. When the limit is
              exceeded, the additional charge is applied. 
            </p>
          </li>
          <li>
            <p>
              CoSupport Customer pricing depends on the volume of AI responses.
              Such a policy is well-suited for environments with high customer
              requests flow and can be adjusted easily to the need for extensive
              automated interaction.
            </p>
          </li>
          <li>
            <p>
              CoSupport BI follows the pricing policy “per user per month,”
              meaning that a particular user can make a fixed number of requests
              per month within a plan; any additional requests are charged
              extra. There is also an option “per team per month” available.
            </p>
          </li>
        </ul>
        <p>
          For each client, a custom pricing model is possible, depending on the
          solution’s complexity.
        </p>
      </div>
    ),
  },
  {
    question:
      'What workflow automation solutions does CoSupport AI offer for customer support?',
    answer: (
      <div>
        <p>
          CoSupport AI's solutions provide AI for customer support solutions,
          automating 80% to 100% of response and data processing tasks. At the
          same time, the extent of automation changes depending on a particular
          solution’s task:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport Agent provides up to 80% of response automation since
              the solution’s task is customer service agent assistance. By
              providing agents with response drafts, the AI assistant decreases
              response time and enhances the fraction of resolved issues.
              Though, the final decision on the response content is made by an
              agent.
            </p>
          </li>
          <li>
            <p>
              CoSupport Customer provides up to 100% of response automation. The
              solution is developed to enhance the volume of processed
              conversations significantly and to scale up customer service
              rapidly and resource-efficiently.
            </p>
          </li>
        </ul>
        <p>
          The third solution in CoSupport AI’s portfolio, CoSupport BI, is hard
          to evaluate in terms of automation since its goal isn't response
          automation but delivering data for business insights through an AI
          chatbot interface.
        </p>
      </div>
    ),
  },
  {
    question: 'Which tools can I integrate CoSupport AI solutions with?',
    answer: (
      <div>
        <p>
          CoSupport AI solutions integrate with all in-demand tools for customer
          service agents and team leads. Here is the list of possible
          integrations:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport Agent integrates with popular CRM systems, such as
              Zendesk or Freshdesk; integration with a custom CRM is also
              possible;
            </p>
          </li>
          <li>
            <p>
              CoSupport Customer integrates with various communication channels
              like email, social media, or website and application chats;
            </p>
          </li>
          <li>
            <p>
              CoSupport BI integrates with business communication tools, such as
              Slack or Microsoft Teams; on the back-end part, it integrates with
              all possible sources of data, including databases, internal
              documentation, knowledge bases, and others.
            </p>
          </li>
        </ul>
        <p>
          If you’re not sure about the possibility of CoSupport AI products
          integration with your favorite tools, contact our team, and we’ll
          apply all our expertise to find a solution.
        </p>
      </div>
    ),
  },
];
