import React from 'react';
import { Typography } from '@/components/Typography/Typography';
import {
  PATH_AGENT,
  PATH_BI,
  PATH_CUSTOMER,
  PATH_INTEGRATION,
} from '@/constants/spa-routes';
import { COLORS } from '@/constants/colors';
import Link from 'next/link';

export const AiForCustomerSupport = () => {
  return (
    <div className="ai-for-customer-support bg-[radial-gradient(50%_54.83%_at_50%_45.17%,#0E2D2B_0%,#05050D_100%)] bg-no-repeat">
      <Typography
        as="h2"
        $type="Heading1"
        $color={COLORS.WHITE}
        className="font-formula mb-1"
      >
        AI for Customer Support & BI 360°
      </Typography>
      <div className="text">
        <p>
          CoSupport™ AI has the power to redefine the way businesses act. From
          HR to finance, customer experience, sales & marketing, analytics, and
          your business operations. With an internal AI copilot, employees could
          simply ask a question or highlight the problem and{' '}
          <span className="bold white">
            receive intelligent answers from the data across your company
            infrastructure.
          </span>
        </p>
      </div>
      <div className="logo-wrapper">
        <div className="logo-icon-wrapper drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
          <img
            alt={'ai-for-customer-support'}
            src="/img/ai-for-customer-support-logo-white.png"
            width={125}
            height={125}
          />
        </div>
        <div className="text">
          <p>
            <span className="extra-bold">co</span>support™
          </p>
        </div>
      </div>
      <div className="arrows desktop">
        <img src="/img/ai-for-customer-support-arrow-color.svg" width="100%" />
      </div>
      <div className="cards desktop">
        <div className="card">
          <svg
            id="ai-for-customer-logo-purple"
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="71"
            viewBox="0 0 70 71"
            fill="none"
          >
            <rect y="0.5" width="70" height="70" rx="8" fill="#A498FF" />
            <path
              d="M26.6823 21.505C22.8133 21.505 19.0086 23.0324 16.2728 25.7512C13.537 28.47 12 32.1575 12 36.0025C12 39.8475 13.537 43.535 16.2728 46.2538C19.0086 48.9726 22.8133 50.5 26.6823 50.5L26.6823 36.0025L26.6823 21.505Z"
              fill="#05050D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M44.4123 50.495C52.4691 50.495 59.0005 44.0043 59.0005 35.9975C59.0005 27.9908 52.4691 21.5 44.4123 21.5C36.3555 21.5 29.8242 27.9908 29.8242 35.9975C29.8242 44.0043 36.3555 50.495 44.4123 50.495ZM44.4124 41.9346C47.7119 41.9346 50.3866 39.2765 50.3866 35.9976C50.3866 32.7186 47.7119 30.0605 44.4124 30.0605C41.113 30.0605 38.4382 32.7186 38.4382 35.9976C38.4382 39.2765 41.113 41.9346 44.4124 41.9346Z"
              fill="#05050D"
            />
          </svg>
          <div className="content">
            <h4>
              <span className="bold">co</span>support™{' '}
              <span className="bold">customer</span>
            </h4>
            <p>
              100% AI handling of up to 70% of inbound chat and email
              communication.
            </p>
          </div>
        </div>
        <div className="card">
          <svg
            id="ai-for-customer-logo-blue"
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="71"
            viewBox="0 0 70 71"
            fill="none"
          >
            <rect y="0.5" width="70" height="70" rx="8" fill="#75F3FB" />
            <path
              d="M26.6823 21.505C22.8133 21.505 19.0086 23.0324 16.2728 25.7512C13.537 28.47 12 32.1575 12 36.0025C12 39.8475 13.537 43.535 16.2728 46.2538C19.0086 48.9726 22.8133 50.5 26.6823 50.5L26.6823 36.0025L26.6823 21.505Z"
              fill="#05050D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M44.4123 50.495C52.4691 50.495 59.0005 44.0043 59.0005 35.9975C59.0005 27.9908 52.4691 21.5 44.4123 21.5C36.3555 21.5 29.8242 27.9908 29.8242 35.9975C29.8242 44.0043 36.3555 50.495 44.4123 50.495ZM44.4124 41.9346C47.7119 41.9346 50.3866 39.2765 50.3866 35.9976C50.3866 32.7186 47.7119 30.0605 44.4124 30.0605C41.113 30.0605 38.4382 32.7186 38.4382 35.9976C38.4382 39.2765 41.113 41.9346 44.4124 41.9346Z"
              fill="#05050D"
            />
          </svg>
          <div className="content">
            <h4>
              <span className="bold">co</span>support™{' '}
              <span className="bold">agent</span>
            </h4>
            <p>
              Boost customer support productivity with instant AI generated
              responses.
            </p>
          </div>
        </div>
        <div className="card">
          <svg
            id="ai-for-customer-logo-yellow"
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="71"
            viewBox="0 0 70 71"
            fill="none"
          >
            <rect y="0.5" width="70" height="70" rx="8" fill="#DFF800" />
            <path
              d="M26.6823 21.505C22.8133 21.505 19.0086 23.0324 16.2728 25.7512C13.537 28.47 12 32.1575 12 36.0025C12 39.8475 13.537 43.535 16.2728 46.2538C19.0086 48.9726 22.8133 50.5 26.6823 50.5L26.6823 36.0025L26.6823 21.505Z"
              fill="#05050D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M44.4123 50.495C52.4691 50.495 59.0005 44.0043 59.0005 35.9975C59.0005 27.9908 52.4691 21.5 44.4123 21.5C36.3555 21.5 29.8242 27.9908 29.8242 35.9975C29.8242 44.0043 36.3555 50.495 44.4123 50.495ZM44.4124 41.9346C47.7119 41.9346 50.3866 39.2765 50.3866 35.9976C50.3866 32.7186 47.7119 30.0605 44.4124 30.0605C41.113 30.0605 38.4382 32.7186 38.4382 35.9976C38.4382 39.2765 41.113 41.9346 44.4124 41.9346Z"
              fill="#05050D"
            />
          </svg>
          <div className="content">
            <h4>
              <span className="bold">co</span>support™{' '}
              <span className="bold">bi</span>
            </h4>
            <p>
              AI Business Analyst that knows everything ever written about your
              product.
            </p>
          </div>
        </div>
      </div>
      <div className="cards mobile">
        <div className="cards-wrapper">
          <div className="card">
            <div className="header">
              <img
                className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw]"
                src="/img/customer-logo-shadow.png"
              />
              <Link href={PATH_CUSTOMER.ROOT}>
                <h4 className={'underline'}>
                  <span className="bold">co</span>support™
                  <span className="bold">customer</span>
                </h4>
              </Link>
            </div>
            <div className="content">
              <p>
                Self-sufficient generative AI for customer support that delivers
                highly accurate responses to your customers all on its own.{' '}
                <span className="white bold">
                  CoSupport™ Customer saves thousands of man-hours
                </span>{' '}
                by handling up to 100% of customers’ requests with its
                comprehensive AI customer care.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="header">
              <img
                className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw]"
                src="/img/agent-logo-shadow.png"
              />
              <Link href={PATH_AGENT.ROOT}>
                <h4 className={'underline'}>
                  <span className="bold">co</span>support™{' '}
                  <span className="bold">agent</span>
                </h4>
              </Link>
            </div>
            <div className="content flex flex-col gap-1.5">
              <p>
                AI agent assistant is designed to boost productivity of your
                human support staff.{' '}
                <span className="white bold">
                  CoSupport™ Agent optimizes and reduces ticket processing time
                </span>{' '}
                by providing reply suggestions, based on context from the entire
                conversation thread.
              </p>
              <Link href={PATH_INTEGRATION.INTEGRATION_ZENDESK}>
                <Typography
                  as="span"
                  $type="PMedium"
                  $color={COLORS.MALIBU}
                  className="cursor-pointer underline italic"
                >
                  Explore how the CoSupport Agent can transform your Zendesk
                  experience  →
                </Typography>
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="header">
              <img
                className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw]"
                src="/img/bi-logo-shadow.png"
              />
              <Link href={PATH_BI.ROOT}>
                <h4 className={'underline'}>
                  <span className="bold">co</span>support™{' '}
                  <span className="bold">bi</span>
                </h4>
              </Link>
            </div>
            <div className="content">
              <p>
                Innovative AI assistant for internal use within your
                organization that{' '}
                <span className="white bold">
                  offers insights based on context relevant to your products and
                  customers.
                </span>{' '}
                To achieve this, we connect to your company’s data sources and
                it gains real live knowledge.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
