import React, { useEffect, useState } from 'react';

const wait = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const useLoader = (delay = 2000) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const hideLoader = () => {
      setLoading(false);
    };

    (async () => {
      document.fonts.addEventListener('loadingdone', hideLoader);

      await wait(delay);
      hideLoader();
    })();

    return () => {
      document.fonts.removeEventListener('loadingdone', hideLoader);
    };
  }, []);
  return loading;
};
