import { motion } from 'framer-motion';
import { clsx } from 'clsx';

const cursorVariants = {
  blinking: {
    opacity: [0, 0, 1, 1],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatDelay: 0,
      ease: 'linear',
      times: [0, 0.5, 0.5, 1],
    },
  },
};

type CursorBlinkerProps = {
  cursorColor?: string;
  className?: string;
};

export default function CursorBlinker({
  cursorColor = 'bg-slate-900',
  className,
}: CursorBlinkerProps) {
  return (
    <motion.span
      variants={cursorVariants}
      animate="blinking"
      className={clsx(
        'inline-block translate-y-1 md:h-4-vw h-4 -mt-2',
        cursorColor,
        className,
      )}
    />
  );
}
