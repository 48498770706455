import React, { memo, useEffect, useRef } from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { GreenButton } from '@/components/ButtonsCollection/GreenButton/GreenButton';
import { COLORS } from '@/constants/colors';
import { SmallArrowIcon } from '@/assets/icons/smallArrowIcon';
import { PATH_DEMO } from '@/constants/spa-routes';
import { BlackButton } from '@/components/ButtonsCollection/BlackButton/BlackButton';
import { PatentSolution } from '@/components/PatentSolution/PatentSolution';
import { LinkArrowIcon } from '@/assets/icons/linkArrowIcon';

export const SuperChargeBlock = memo(() => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5;
    }
  }, [videoRef]);

  return (
    <div className="supercharge video-container md:min-h-[890px] bg-[black]">
      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        loop
        disablePictureInPicture
        poster="/img/Video_2.0_frame.png"
      >
        <source src={'/video/Video_cosupport_2.0.mp4'} type="video/mp4" />
      </video>
      <div className="content-container md:gap-20-vw">
        <Container className="flex justify-center">
          <div className="supercharge-title">
            <div className="flex flex-col md:gap-5-vw gap-2">
              <Typography
                as="h2"
                $weight="700"
                className="font-formula"
                $color={COLORS.WHITE}
                $type="Heading1"
              >
                SuperCharge your Business with CoSupport™ AI Assist 360°:{' '}
              </Typography>
              <Typography $type="Heading2">
                <span className="blue">C</span>ustomer Support +{' '}
                <span className="purple">C</span>ustomer Service +{' '}
                <span className="yellow">B</span>usiness Intelligence{' '}
              </Typography>
            </div>
            <div className="supercharge-body">
              <div className="button-container flex md:gap-5-vw gap-4 max-md:flex-col max-md:w-full">
                <GreenButton
                  url={PATH_DEMO.ROOT}
                  className="whitespace-nowrap max-md:w-full"
                  linkBtnClassName="max-md:w-full"
                >
                  <Typography
                    as="span"
                    $color={COLORS.EBONY}
                    $type="Heading4"
                    $weight="700"
                  >
                    Request Demo
                  </Typography>
                </GreenButton>
                <BlackButton
                  url={PATH_DEMO.CUSTOMER_DEMO}
                  className="whitespace-nowrap"
                  linkBtnClassName="max-md:w-full"
                >
                  <Typography
                    as="span"
                    $color={COLORS.WHITE}
                    $type="Heading4"
                    $weight="700"
                  >
                    Try It Now
                  </Typography>
                  <SmallArrowIcon />
                </BlackButton>
              </div>
              <div className="text">
                <PatentSolution />
              </div>
            </div>
          </div>
        </Container>
        <div className="supercharge-slider-wrapper min-h-[310px]">
          <div className="supercharge-slider">
            <div className="supercharge-card py-[30px] px-[26px] md:py-[2.083vw] md:px-[2.778vw]">
              <div className="flex items-center gap-2.5 md:gap-2.5-vw absolute md:-top-3.5-vw -top-3.5 md:right-6-vw right-6">
                <LinkArrowIcon />
                <img
                  className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw] min-w-[45px] md:min-w-[3.125vw]"
                  src="/img/bi-logo-shadow.png"
                />
              </div>
              <div className="flex md:gap-5-vw gap-3.5">
                <img
                  className="md:h-[4.861vw] md:w-[4.861vw] h-[70px] w-[70px]"
                  src="/img/comment-person-1.png"
                />
                <Typography className="text-left">
                  <span className="white">
                    Write a landing <br /> page copy
                  </span>{' '}
                  for #1 requested <br className="max-md:hidden" /> feature
                </Typography>
              </div>
            </div>
            <div className="supercharge-card py-[30px] pl-[26px] pr-[15px] md:py-[2.083vw] md:pl-[2.778vw] md:pr-[2.778vw]">
              <div className="flex items-center gap-2.5 md:gap-2.5-vw absolute md:-top-3.5-vw -top-3.5 md:right-6-vw right-6">
                <LinkArrowIcon color={COLORS.PURPLE} />
                <img
                  className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw] min-w-[45px] md:min-w-[3.125vw]"
                  src="/img/customer-logo-shadow.png"
                />
              </div>
              <div className="flex md:gap-5-vw gap-3.5">
                <img
                  className="md:h-[4.861vw] md:w-[4.861vw] h-[70px] w-[70px]"
                  src="/img/comment-person-2.png"
                />
                <Typography className="text-left">
                  <span className="white">
                    I would like to <br /> update my plan.
                  </span>{' '}
                  But I <br className="md:hidden block" /> can't{' '}
                  <br className="max-md:hidden" /> find how to do it right.
                </Typography>
              </div>
            </div>
            <div className="supercharge-card py-[30px] px-[26px] md:py-[2.083vw] md:px-[2.778vw]">
              <div className="flex items-center gap-2.5 md:gap-2.5-vw absolute md:-top-3.5-vw -top-3.5 md:right-6-vw right-6">
                <LinkArrowIcon color={COLORS.MALIBU} />
                <img
                  className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw] min-w-[45px] md:min-w-[3.125vw]"
                  src="/img/agent-logo-shadow.png"
                />
              </div>
              <div className="flex md:gap-5-vw gap-3.5">
                <img
                  className="md:h-[4.861vw] md:w-[4.861vw] h-[70px] w-[70px]"
                  src="/img/comment-person-3.png"
                />
                <Typography className="text-left">
                  <span className="white">Can you assist me</span> <br />
                  in updating my payment <br /> information?
                </Typography>
              </div>
            </div>
            <div className="supercharge-card py-[30px] px-[26px] md:py-[2.083vw] md:px-[2.778vw]">
              <div className="flex items-center gap-2.5 md:gap-2.5-vw absolute md:-top-3.5-vw -top-3.5 md:right-6-vw right-6">
                <LinkArrowIcon />
                <img
                  className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw] min-w-[45px] md:min-w-[3.125vw]"
                  src="/img/bi-logo-shadow.png"
                />
              </div>
              <div className="flex md:gap-5-vw gap-3.5">
                <img
                  className="md:h-[4.861vw] md:w-[4.861vw] h-[70px] w-[70px]"
                  src="/img/comment-person-1.png"
                />
                <Typography className="text-left">
                  <span className="white">
                    Write a landing <br /> page copy
                  </span>{' '}
                  for #1 requested <br className="max-md:hidden" /> feature
                </Typography>
              </div>
            </div>
            <div className="supercharge-card py-[30px] pl-[26px] pr-[15px] md:py-[2.083vw] md:pl-[2.778vw] md:pr-[2.778vw]">
              <div className="flex items-center gap-2.5 md:gap-2.5-vw absolute md:-top-3.5-vw -top-3.5 md:right-6-vw right-6">
                <LinkArrowIcon color={COLORS.PURPLE} />
                <img
                  className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw] min-w-[45px] md:min-w-[3.125vw]"
                  src="/img/customer-logo-shadow.png"
                />
              </div>
              <div className="flex md:gap-5-vw gap-3.5">
                <img
                  className="md:h-[4.861vw] md:w-[4.861vw] h-[70px] w-[70px]"
                  src="/img/comment-person-2.png"
                />
                <Typography className="text-left">
                  <span className="white">
                    I would like to <br /> update my plan.
                  </span>{' '}
                  But I <br className="md:hidden block" /> can't{' '}
                  <br className="max-md:hidden" /> find how to do it right.
                </Typography>
              </div>
            </div>
            <div className="supercharge-card py-[30px] px-[26px] md:py-[2.083vw] md:px-[2.778vw]">
              <div className="flex items-center gap-2.5 md:gap-2.5-vw absolute md:-top-3.5-vw -top-3.5 md:right-6-vw right-6">
                <LinkArrowIcon color={COLORS.MALIBU} />
                <img
                  className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw] min-w-[45px] md:min-w-[3.125vw]"
                  src="/img/agent-logo-shadow.png"
                />
              </div>
              <div className="flex md:gap-5-vw gap-3.5">
                <img
                  className="md:h-[4.861vw] md:w-[4.861vw] h-[70px] w-[70px]"
                  src="/img/comment-person-3.png"
                />
                <Typography className="text-left">
                  <span className="white">Can you assist me</span> <br />
                  in updating my payment <br /> information?
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
