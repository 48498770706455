import React from 'react';
import {
  Typography,
  TypographyAs,
  TypographyType,
} from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { AnimatePresence, motion } from 'framer-motion';
import { clsx } from 'clsx';

type TextSwitchProps = {
  className?: string;
  type?: TypographyType;
  currentSlide: number;
  as?: TypographyAs;
  texts: {
    text: string;
    color: string;
  }[];
};

export const TextSwitch = ({
  className = '',
  currentSlide,
  texts = [{ text: '', color: '' }],
  type = 'Heading2',
  as = 'h1',
}: TextSwitchProps) => {
  return (
    <AnimatePresence initial={false}>
      <motion.span
        className={clsx(className, 'block')}
        key={currentSlide}
        layout
        variants={{
          enter: {
            translateY: 20,
            opacity: 0,
            height: 0,
          },
          center: {
            zIndex: 1,
            translateY: 0,
            opacity: 1,
            height: 'auto',
          },
          exit: {
            zIndex: 0,
            translateY: -20,
            opacity: 0,
            height: 0,
          },
        }}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          translateY: { stiffness: 1000, damping: 200, duration: 1 },
        }}
      >
        <Typography
          $breakpoint="1024"
          as={as}
          $type={type}
          $color={texts[currentSlide]?.color || COLORS.WHITE}
          className="text-start"
        >
          {texts[currentSlide].text}
        </Typography>
      </motion.span>
    </AnimatePresence>
  );
};
