import React from 'react';

type PropType = {
  children: React.ReactNode;
  imgSrc?: string;
};

export const HumanComment = ({
  children,
  imgSrc = '/img/ba-human-icon.png',
}: PropType) => {
  return (
    <div className="slider-slide right">
      <div className="human-message-container md:px-10-vw ">
        <div className="message-icon z-10 md:right-3.5-vw right-[5px]">
          <img
            className="md:h-[3.472vw] md:w-[3.472vw] w-[40px] h-[40px]"
            width="50"
            height="50"
            src={imgSrc}
          />
        </div>
        <div className="message-text-block relative md:py-5-vw md:pr-9-vw md:pl-6-vw py-3.5 pr-6 pl-2.5">
          <div className="relative">{children}</div>
        </div>
      </div>
    </div>
  );
};
