import React, { useEffect, useRef } from 'react';
import Link from 'next/link';
import { CoSupportBi } from '../../Desktop/Sections/HowSolutionHelp/CoSupportBi';
import { Typography } from '@/components/Typography/Typography';
import { BlackButton } from '@/components/ButtonsCollection/BlackButton/BlackButton';
import { COLORS } from '@/constants/colors';
import { SmallArrowIcon } from '@/assets/icons/smallArrowIcon';
import { PATH_AGENT, PATH_CUSTOMER } from '@/constants/spa-routes';

export const HowSolutionHelp = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoRef2 = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1;
    }
    if (videoRef2.current) {
      videoRef2.current.playbackRate = 1;
    }
  }, [videoRef, videoRef2]);
  return (
    <div className="how-solution-help max-md:pb-3.5">
      <Typography
        as="h2"
        $type="Heading1"
        className="md:max-w-[59.028vw] md:mb-8 font-formula text-center m-0"
      >
        What benefits does each CoSupportTM AI copilot brings?
      </Typography>
      <div className="block-el">
        <div>
          <div className="title">
            <Typography as="h4" $type="PRegular" $weight="700">
              <b>CoSupport™ Customer</b>
            </Typography>
          </div>
          <div className="designed-for px-2.5 py-1">
            Designed for Customer Self Service
          </div>
          <div className="text">
            <p>
              With CoSupport™ Customer your{' '}
              <span className="dark bold">
                human agents can focus on complex issues.
              </span>{' '}
              Our AI for customer support works seamlessly with your human
              support staff and manages up to 70% of typical questions across
              multiple channels.
            </p>
          </div>
          <div className="button-container">
            <Link
              href={PATH_CUSTOMER.ROOT}
              className="w-fit block max-md:w-full"
            >
              <BlackButton className="max-md:w-full">
                <div className="button-text flex justify-center items-center md:gap-4-vw gap-4">
                  <Typography
                    $color={COLORS.WHITE}
                    as="span"
                    $type="PRegular"
                    $weight="700"
                  >
                    Learn More
                  </Typography>
                  <SmallArrowIcon color="purple" />
                </div>
              </BlackButton>
            </Link>
          </div>
        </div>
        <div className="video-block">
          <video
            ref={videoRef}
            data-mobile="1"
            data-desktop="0"
            autoPlay
            muted
            playsInline
            loop
            disablePictureInPicture
          >
            <source
              src={'/video/how_can_help_customer_mobile.mp4'}
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div className="block-el">
        <div className="video-block">
          <video
            ref={videoRef2}
            data-mobile="1"
            data-desktop="0"
            autoPlay
            muted
            playsInline
            loop
            disablePictureInPicture
          >
            <source
              src={'/video/how_can_help_agent_mobile.mp4'}
              type="video/mp4"
            />
          </video>
        </div>
        <div>
          <div className="title">
            <Typography as="h4" $type="PRegular" $weight="700">
              <b>CoSupport™ Agent</b>
            </Typography>
          </div>
          <div className="designed-for-wrapper mobile">
            <div className="designed-for px-2.5 py-1">
              AI Agent Assistant Designed
            </div>
            <div className="designed-for px-2.5 py-1">
              for Customer Support Teams{' '}
            </div>
          </div>
          <div className="text">
            <p>
              Now you can process{' '}
              <span className="bold dark">requests faster and easier.</span>{' '}
              With CoSupport™ Agent, your support team can process tickets{' '}
              <span className="bold dark">30-80% faster.</span> You receive the
              customer’s email and an AI agent assistant{' '}
              <span className="bold dark">
                generates unique reply suggestions
              </span>{' '}
              based on actual context.
            </p>
          </div>
          <div className="button-container">
            <Link href={PATH_AGENT.ROOT} className="w-fit block max-md:w-full">
              <BlackButton className="max-md:w-full">
                <div className="button-text flex justify-center items-center md:gap-4-vw gap-4">
                  <Typography
                    $color={COLORS.WHITE}
                    as="span"
                    $type="PRegular"
                    $weight="700"
                  >
                    Learn More
                  </Typography>
                  <SmallArrowIcon color="blue" />
                </div>
              </BlackButton>
            </Link>
          </div>
        </div>
      </div>
      <CoSupportBi />
    </div>
  );
};
