'use client';
import React, { Suspense, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FirstSection } from './FirstSection/FirstSection';

const StyledVideo = styled.video({
  width: '100%',
  objectFit: 'cover',
  zIndex: -100,
  top: 0,
});

type MainSectionProps = {
  children?: React.ReactNode;
};

export const MeinSections = ({ children }: MainSectionProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5;
    }
  }, [videoRef]);
  return (
    <section className="relative bg-black z-0">
      <Suspense fallback={<p>Loading video...</p>}>
        <StyledVideo
          className="md:fixed md:min-h-screen absolute min-h-full bg-black"
          ref={videoRef}
          autoPlay
          muted
          playsInline
          loop
          disablePictureInPicture
          poster="/img/Video_2.0_frame.png"
        >
          <source src={'/video/Video_cosupport_2.0.mp4'} type="video/mp4" />
        </StyledVideo>
      </Suspense>
      <FirstSection />
      {children}
    </section>
  );
};
