import React, { useEffect } from 'react';
import Link from 'next/link';
import { Filter } from '@/components/Filter/Filter';
import { IconComponent } from './IconComponent';
import { useAnimate } from 'framer-motion';
import {
  agentAnimation,
  agentAnimationOut,
  biAnimation,
  biAnimationOut,
  customerAnimation,
  customerAnimationOut,
} from './animationsSequence';
import { SmallArrowIcon } from '@/assets/icons/smallArrowIcon';
import { Typography } from '@/components/Typography/Typography';
import { BlackButton } from '@/components/ButtonsCollection/BlackButton/BlackButton';
import { COLORS } from '@/constants/colors';
import {
  PATH_AGENT,
  PATH_BI,
  PATH_CUSTOMER,
  PATH_DEMO,
} from '@/constants/spa-routes';
import { TextSwitch } from '@/components/TextSwitch/TextSwitch';

type Props = {
  selectedFilter: number;
  handleFilterChange: (value: string) => void;
};

const iconsBi = [
  { icon: '/img/connect-cosupport-bi-1.png', helpText: 'ClickUp' },
  { icon: '/img/connect-cosupport-bi-2.png', helpText: 'Rocket Chat' },
  { icon: '/img/connect-cosupport-bi-3.png', helpText: 'Slack' },
  { icon: '/img/connect-cosupport-bi-4.png', helpText: 'Discord' },
  { icon: '/img/connect-cosupport-bi-5.png', helpText: 'Microsoft Teams' },
  {
    icon: '/img/connect-cosupport-bi-6.png',
    helpText: 'Mattermost*',
  },
];
const iconsCustomer = [
  { icon: '/img/connect-cosupport-customer-1.png', helpText: 'Freshdesk' },
  { icon: '/img/connect-cosupport-customer-2.png', helpText: 'Zendesk' },
  { icon: '/img/connect-cosupport-customer-3.png', helpText: 'Intercom' },
  { icon: '/img/connect-cosupport-customer-4.png', helpText: 'Gorgias' },
  { icon: '/img/connect-cosupport-customer-5.png', helpText: 'Zoho Desk' },
  {
    icon: '/img/connect-cosupport-customer-6.png',
    helpText: 'Help scout*',
  },
];
const iconsAgent = [
  { icon: '/img/connect-cosupport-customer-2.png', helpText: 'Zendesk' },
  { icon: '/img/connect-cosupport-customer-5.png', helpText: 'Zoho Desk' },
  { icon: '/img/connect-cosupport-customer-1.png', helpText: 'Freshdesk' },
  { icon: '/img/connect-cosupport-customer-3.png', helpText: 'Intercom' },
  { icon: '/img/connect-cosupport-customer-6.png', helpText: 'Help scout' },
  {
    icon: '/img/connect-cosupport-customer-4.png',
    helpText: 'Gorgias*',
  },
];

const texts = [
  <>
    CoSupport™ BI is an internal AI copilot that integrates with your business
    messenger of choice
  </>,
  <>
    CoSupport™ Customer
    <br /> is an autonomous AI system that integrates into
  </>,
  <>
    CoSupport™ Agent
    <br /> is an AI copilot for your support agents that integrates into
  </>,
];

const filterContent = [
  {
    id: '0',
    activeColor: 'data-[state=active]:bg-chartreuse_yellow',
    text: (
      <Typography>
        <span className="bold">co</span>support™{' '}
        <span className="bold">bi</span>
      </Typography>
    ),
  },
  {
    id: '1',
    activeColor: 'data-[state=active]:bg-purple',
    text: (
      <Typography>
        <span className="bold">co</span>support™{' '}
        <span className="bold">customer</span>
      </Typography>
    ),
  },
  {
    id: '2',
    activeColor: 'data-[state=active]:bg-malibu',
    text: (
      <Typography>
        <span className="bold">co</span>support™{' '}
        <span className="bold">agent</span>
      </Typography>
    ),
  },
];

export const IconsPart = ({ selectedFilter, handleFilterChange }: Props) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (selectedFilter === 0) {
      animate(biAnimation);
    } else {
      animate(biAnimationOut);
    }
    if (selectedFilter === 1) {
      animate(customerAnimation);
    } else {
      animate(customerAnimationOut);
    }
    if (selectedFilter === 2) {
      animate(agentAnimation);
    } else {
      animate(agentAnimationOut);
    }
  }, [selectedFilter]);

  return (
    <div className="icon-container md:gap-6-vw gap-5 animate__animated animate__fadeIn md:w-1/2 z-10 max-md:px-5 pb-5-vw">
      <div className="md:h-24 lg:h-[6.667vw]"></div>
      <div className="title flex justify-center">
        <div className="relative max-md:pt-7 md:max-w-[70%]">
          <TextSwitch texts={texts} currentSlide={selectedFilter} />
        </div>
      </div>
      <div className="w-full md:absolute flex justify-center items-center md:top-[2.1vw] md:right-0 relative">
        <Filter
          onValueChange={handleFilterChange}
          filterContent={filterContent}
        />
      </div>
      <div
        className="w-full md:min-h-[20.75vw] lg:min-h-[18.75vw] min-h-[270px] flex justify-center"
        ref={scope}
      >
        <div
          id="bi"
          className="flex md:max-w-[19.792vw] max-w-[285px] flex-wrap justify-center md:gap-3.5-vw gap-3.5 absolute"
        >
          {iconsBi.map((icon, index) => {
            return <IconComponent key={`${icon.icon}${index}`} icon={icon} />;
          })}
        </div>
        <div
          id="customer"
          className="flex md:max-w-[19.792vw] max-w-[285px] flex-wrap justify-center md:gap-3.5-vw gap-3.5 absolute"
        >
          {iconsCustomer.map((icon, index) => {
            return <IconComponent key={`${icon.icon}${index}`} icon={icon} />;
          })}
        </div>
        <div
          id="agent"
          className="flex md:max-w-[19.792vw] max-w-[285px] flex-wrap justify-center md:gap-3.5-vw gap-3.5 absolute"
        >
          {iconsAgent.map((icon, index) => {
            return <IconComponent key={`${icon.icon}${index}`} icon={icon} />;
          })}
        </div>
      </div>
      <div className="max-md:w-full">
        <Link
          href={
            (selectedFilter === 0 && PATH_BI.ROOT) ||
            (selectedFilter === 1 && PATH_CUSTOMER.ROOT) ||
            (selectedFilter === 2 && PATH_AGENT.ROOT) ||
            '/'
          }
          className="max-md:w-full"
        >
          <BlackButton className="max-md:w-full">
            <div className="button-text flex justify-center items-center md:gap-4-vw gap-4">
              <Typography
                $color={COLORS.WHITE}
                as="span"
                $type="PRegular"
                $weight="700"
              >
                Learn More
              </Typography>
              <SmallArrowIcon
                color={
                  (selectedFilter === 0 && 'yellow') ||
                  (selectedFilter === 1 && 'purple') ||
                  (selectedFilter === 2 && 'blue') ||
                  'yellow'
                }
              />
            </div>
          </BlackButton>
        </Link>
      </div>
      <div className="bottom-text">
        <Typography $type="PSmall">
          {selectedFilter === 0 && '* and other CRM systems.'}
          {selectedFilter === 1 && '* and other customer services.'}
          {selectedFilter === 2 && '* and other agent services.'}
        </Typography>
      </div>
    </div>
  );
};
