import React from 'react';
import { CoSupportIcon } from '@/assets/icons/coSupportIcon';

type PropType = {
  children: React.ReactNode;
};

export const AiComment = ({ children }: PropType) => {
  return (
    <div className="slider-slide">
      <div className="ai-message-container md:pl-4-vw pl-3">
        <div className="message-icon">
          <CoSupportIcon className="md:h-[3.472vw] md:w-[3.472vw] w-[40px] h-[40px]" />
        </div>
        <div className="message-text-block md:py-5-vw md:pr-6-vw md:pl-12-vw md:ml-7-vw py-3.5 pr-2.5 pl-8 ml-4">
          {children}
        </div>
      </div>
    </div>
  );
};
