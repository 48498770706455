import React, { ReactElement, useState } from 'react';
import { Typography } from '@/components/Typography/Typography';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Loader } from '@/components/Loader/Loader';

type YouTubeVideoPopUpProps = {
  triggerElement: ReactElement;
  iframeSrc: string;
  title?: string;
};

export const YouTubeVideoPopUp = ({
  triggerElement,
  iframeSrc,
  title = '',
}: YouTubeVideoPopUpProps) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) {
          setTimeout(() => setIsVideoLoaded(false), 300);
        }
      }}
    >
      <DialogTrigger>{triggerElement}</DialogTrigger>
      <DialogContent className="w-full sm:w-[calc(100%-2rem)] md:w-[calc(100%-144px)] max-lg:max-w-screen-md max-w-screen-xl">
        <DialogHeader>
          <DialogTitle asChild>
            <Typography
              as="h2"
              $weight="700"
              $type="Heading3"
              className="max-md:mt-3"
            >
              {title}
            </Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="aspect-video rounded-xl overflow-hidden relative">
          {!isVideoLoaded && <Loader className="relative" />}
          <iframe
            width="100%"
            height="100%"
            src={iframeSrc}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            onLoad={() => setIsVideoLoaded(true)}
          ></iframe>
        </div>
      </DialogContent>
    </Dialog>
  );
};
