import React from 'react';
import styled from 'styled-components';
import { PATH_DEMO } from '@/constants/spa-routes';
import { GreenButton } from '@/components/ButtonsCollection/GreenButton/GreenButton';
import { BlackButton } from '@/components/ButtonsCollection/BlackButton/BlackButton';
import { SmallArrowIcon } from '@/assets/icons/smallArrowIcon';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { clsx } from 'clsx';
import { PatentSolution } from '@/components/PatentSolution/PatentSolution';
import { TextSwitch } from '@/components/PagesSections/MainPageSections/FirstSection/Content/TextBlock/TextSwitch';

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

type TextBlockProps = {
  className?: string;
  currentSlide: number;
};

const texts = [
  {
    text: 'for Customer Support Agents',
    color: COLORS.MALIBU,
  },
  {
    text: 'to Fully Automate Customer Requests',
    color: COLORS.PURPLE,
  },
  {
    text: 'for Business Analysis and Decision-Making',
    color: COLORS.CHARTREUSE_YELLOW,
  },
];

export const TextBlock = ({ className, currentSlide }: TextBlockProps) => {
  return (
    <div
      className={clsx(
        'flex flex-col md:gap-10-vw gap-6 justify-center',
        className,
      )}
    >
      <div className="text-container flex flex-col md:gap-5-vw">
        <div className="max-sm:min-h-[125px]">
          <Typography
            $breakpoint="1024"
            as="h1"
            $type="Heading2MainScreen"
            $color={COLORS.WHITE}
            className="text-start !pr-2"
          >
            AI Copilot for Customer Support <br />
            Powered by Your Company's Data
          </Typography>
          <TextSwitch
            texts={texts}
            currentSlide={currentSlide}
            type="Heading2MainScreen"
            className="overflow-hidden font-formula"
            as={'span'}
          />
        </div>
        <Typography
          $type="PMedium"
          $color={COLORS.WHITE}
          className="lg:max-w-[37.5vw] max-md:hidden"
        >
          A faster, more precise, cost-efficient, and secure patented AI
          solution, designed for Customer Support and Business Intelligence,
          unlike general-purpose language models
        </Typography>
      </div>
      <ButtonsContainer className="md:gap-5 gap-4">
        <div className="flex max-md:w-full sm:gap-5 gap-4 flex-wrap">
          <GreenButton
            linkBtnClassName="max-md:w-full"
            url={PATH_DEMO.ROOT}
            className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
          >
            <Typography $type="Heading4" $weight="700">
              Request Demo
            </Typography>
          </GreenButton>
          <BlackButton
            url={PATH_DEMO.CUSTOMER_DEMO}
            className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
            linkBtnClassName="max-md:w-full"
          >
            <Typography $color={COLORS.WHITE} $type="Heading4" $weight="700">
              chat with AI
            </Typography>
            <SmallArrowIcon />
          </BlackButton>
        </div>
        <PatentSolution className="max-lg:hidden" />
      </ButtonsContainer>
    </div>
  );
};
