import React from 'react';
import styled from 'styled-components';
import { Content } from './Content/Content';

const SectionContainer = styled.section({
  minHeight: '93.5vh',
  width: '100%',
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ContentContainer = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const FirstSection = () => {
  return (
    <SectionContainer className="lg:sticky relative">
      <ContentContainer className="lg:mt-20 mt-24 lg:mb-0 mb-14">
        <Content />
      </ContentContainer>
    </SectionContainer>
  );
};
