import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';

export const PowerGenAiCopilot = () => {
  return (
    <div className="relative z-1 bg-ebony">
      <Container>
        <div className="power-gen-ai-copilot">
          <div className="content">
            <div className="md:rounded-3xl overflow-hidden">
              <img
                alt="power-gen-ai-copilot"
                src="/img/power-gen-ai-copilot.png"
                width="100%"
              />
            </div>
            <div className="two-columns md:gap-6-vw gap-6">
              <div>
                <Typography
                  as="h2"
                  $type="Heading1"
                  className="font-formula"
                  $color={COLORS.WHITE}
                >
                  The Power of GenAI Copilots for Customer Support and Internal
                  Productivity
                </Typography>
              </div>
              <div>
                <Typography $type="Heading4">
                  The use of generative AI tools for customer support has the
                  potential to amplify customer satisfaction, enhance employees’
                  decision-making processes, and elevate the productivity of
                  Customer Service teams.
                </Typography>
                <Typography $type="Heading4">
                  Nowadays{' '}
                  <span className="white bold">
                    companies can have their own AI for customer support
                    solutions
                  </span>{' '}
                  that studies your products and customers which then turns this
                  knowledge into invaluable business insights in a way no human
                  can.
                </Typography>
              </div>
            </div>
            <div className="three-columns md:px-24-vw bg-[radial-gradient(50%_54.83%_at_50%_62.17%,#0E2D2B_0%,#05050D_100%)] bg-[center_top_9rem] bg-no-repeat">
              <div className="md:mb-14-vw mb-14">
                <img
                  className="md:h-32-vw md:w-32-vw"
                  alt="absorb-icon"
                  src="/img/absorb-icon.png"
                  width={130}
                />
                <Typography as="h6" $type="PMedium">
                  Can automate work activities that{' '}
                  <span className="extra-bold white italic">
                    absorb <span className="blue">60%</span> to{' '}
                    <span className="blue">70%</span> of employees’ time
                  </span>{' '}
                  today.
                </Typography>
              </div>
              <div className="md:mb-14-vw mb-14">
                <img
                  className="md:h-32-vw md:w-32-vw"
                  alt="generative-ai"
                  src="/img/generative-ai-icon.png"
                  width={130}
                />
                <Typography as="h6" $type="PMedium">
                  Around <span className="italic extra-bold purple">60%</span>{' '}
                  <span className="italic extra-bold white">
                    of businesses integrate generative AI
                  </span>{' '}
                  into their work environments.
                </Typography>
              </div>
              <div className="md:mb-14-vw mb-14">
                <img
                  className="md:h-32-vw md:w-32-vw"
                  alt="coin-icon"
                  src="/img/coin-icon.png"
                  width={130}
                />
                <Typography as="h6" $type="PMedium">
                  Generative AI copilots have the potential to{' '}
                  <span className="extra-bold white italic">
                    generate <span className="yellow">$2.6</span> trillion to{' '}
                    <span className="yellow">$4.4</span> trillion
                  </span>{' '}
                  in value across all industries.{' '}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
