import React from 'react';
import Link from 'next/link';
import { Typography } from '@/components/Typography/Typography';
import { BlackButton } from '@/components/ButtonsCollection/BlackButton/BlackButton';
import { COLORS } from '@/constants/colors';
import { SmallArrowIcon } from '@/assets/icons/smallArrowIcon';
import { BiAnimationBlock } from './Animation/BiAnimationBlock';
import styled from 'styled-components';
import { PATH_BI } from '@/constants/spa-routes';

const StyledWrapper = styled.div({
  background:
    'linear-gradient(223.94deg, #DFF800 0%, #33FF71 33.25%, #75F3FB 66.5%, #99C8FF 98.22%)',
});

export const CoSupportBi = () => {
  return (
    <StyledWrapper className="flex p-px items-center md:rounded-[20px] rounded-[10px] w-full relative overflow-hidden">
      <div className="bg-light md:rounded-[20px] rounded-[10px]">
        <div className="flex lg:flex-row flex-col lg:gap-[86px] gap-8 md:px-[7.639vw] lg:py-[4.167vw] px-5 pt-20 pb-6 w-full">
          <div className="absolute lg:max-w-[14.097vw] top-0 left-0 lg:rounded-br-3xl lg:p-6-vw py-2 max-lg:w-full flex justify-center bg-[linear-gradient(218.45deg,#DFF800_4.77%,#33FF71_46.63%,#75F3FB_93.87%)]">
            <Typography $type="PMedium" $weight="700">
              Innovative Solution
            </Typography>
          </div>
          <div className="lg:w-1/2 flex flex-col lg:gap-5-vw gap-5 justify-center">
            <div className="flex flex-col justify-center lg:gap-5-vw gap-5 lg:max-w-[34.375vw]">
              <div className="flex flex-col lg:gap-5-vw gap-1">
                <Typography as="h4" $type="Heading2">
                  <span className="bold">CoSupport™ BI</span>
                </Typography>
                <Typography
                  $type="PRegular"
                  $weight="700"
                  className="opacity-60 font-formula"
                  $lineHeight={{ sm: '160%' }}
                >
                  Get an internal AI copilot that delivers game-changing
                  insights to key areas of your organization
                </Typography>
              </div>
              <div className="text">
                <Typography $type="Heading4">
                  Your own generative AI for customer support for internal use
                  in your organization that is available 24/7.{' '}
                  <b>It’s got all the insights into your business processes</b>{' '}
                  and delivers the necessary information based on communication
                  with your customers.
                </Typography>
              </div>
            </div>
            <div className="button-container lg:mt-3.5">
              <Link href={PATH_BI.ROOT} className="w-fit block max-md:w-full">
                <BlackButton className="max-md:w-full">
                  <div className="button-text flex justify-center items-center lg:gap-4-vw gap-4">
                    <Typography
                      $color={COLORS.WHITE}
                      as="span"
                      $type="PRegular"
                      $weight="700"
                    >
                      Learn More
                    </Typography>
                    <SmallArrowIcon color="yellow" />
                  </div>
                </BlackButton>
              </Link>
            </div>
          </div>
          <BiAnimationBlock />
        </div>
      </div>
    </StyledWrapper>
  );
};
