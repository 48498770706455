import React, { useState } from 'react';
import { Typography } from '@/components/Typography/Typography';
import { Filter } from '@/components/Filter/Filter';
import { TrackComponent } from './TrackComponent';
import {
  commentsBusinessAnalyst,
  commentsExecutiveManager,
  commentsMarketer,
  commentsProductManager,
} from './trackComments';

const filterContent = [
  {
    id: '0',
    activeColor:
      'data-[state=active]:bg-ebony data-[state=active]:text-chartreuse_yellow',
    text: (
      <Typography $type="PLittle" $color="inherit">
        Business Analyst
      </Typography>
    ),
  },
  {
    id: '1',
    activeColor:
      'data-[state=active]:bg-ebony data-[state=active]:text-chartreuse_yellow',
    text: (
      <Typography $type="PLittle" $color="inherit">
        Product manager
      </Typography>
    ),
  },
  {
    id: '2',
    activeColor:
      'data-[state=active]:bg-ebony data-[state=active]:text-chartreuse_yellow',
    text: (
      <Typography $type="PLittle" $color="inherit">
        Marketer
      </Typography>
    ),
  },
  {
    id: '3',
    activeColor:
      'data-[state=active]:bg-ebony data-[state=active]:text-chartreuse_yellow',
    text: (
      <Typography $type="PLittle" $color="inherit">
        Executive manager
      </Typography>
    ),
  },
];

export const BiAnimationBlock = () => {
  const [selectedFilter, setSelectedFilter] = useState(0);

  const handleFilterChange = (e: string) => {
    setSelectedFilter(Number(e));
  };

  return (
    <div className="video-block lg:gap-3.5-vw gap-4  lg:w-1/2 flex justify-center flex-col">
      <div className="lg:gap-2.5-vw gap-4 flex flex-col">
        <div>
          <Typography className="italic opacity-50" $type="PLittle">
            Lets see how CoSupport™ BI works for different departments.{' '}
            <b className="underline-offset-1 underline">Choose your role ↓</b>
          </Typography>
        </div>
        <Filter
          // isMobile={false}
          classNameTabsList="lg:flex lg:flex-wrap w-full lg:gap-0 lg:justify-between md:flex-nowrap lg:flex-nowrap"
          className="w-full"
          containerClassName="w-full"
          onValueChange={handleFilterChange}
          filterContent={filterContent}
        />
      </div>
      <div className="content-container">
        <div className="bg-white rounded-xl overflow-hidden lg:h-[40.139vw] lg:w-auto max-lg:h-[538px] relative">
          {selectedFilter === 0 && (
            <TrackComponent comments={commentsBusinessAnalyst} />
          )}
          {selectedFilter === 1 && (
            <TrackComponent comments={commentsProductManager} />
          )}
          {selectedFilter === 2 && (
            <TrackComponent comments={commentsMarketer} />
          )}
          {selectedFilter === 3 && (
            <TrackComponent comments={commentsExecutiveManager} />
          )}
        </div>
      </div>
    </div>
  );
};
