import React from 'react';
import { ImageScroll } from './Sections/ImageScroll';
import { AiForCustomerSupport } from './Sections/AiForCustomerSupport';
import { PowerGenAiCopilot } from './Sections/PowerGenAiCopilot';
import { HowSolutionHelp } from './Sections/HowSolutionHelp';

export const ImageScrollSectionMobile = () => {
  return (
    <section className="image-scroll-container-mobile container-global mobile">
      <ImageScroll />
      <AiForCustomerSupport />
      <PowerGenAiCopilot />
      <HowSolutionHelp />
    </section>
  );
};
