import React, { ReactNode } from 'react';
import { clsx } from 'clsx';

type TextBlockProps = {
  className?: string;
  title: ReactNode | string;
  subtitle: ReactNode | string;
  bottomContent: ReactNode | string;
};

export const TextBlock = ({
  className,
  title,
  subtitle,
  bottomContent,
}: TextBlockProps) => {
  return (
    <div
      className={clsx(
        'flex flex-col md:gap-10-vw gap-5 justify-center',
        className,
      )}
    >
      <div className="text-container flex flex-col  md:gap-5-vw gap-2.5">
        {title}
        <div>
          {subtitle}
          {bottomContent}
        </div>
      </div>
    </div>
  );
};
