import React from 'react';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';

export const PowerGenAiCopilot = () => {
  return (
    <div className="power-gen-ai-copilot">
      <div className="content">
        <img
          alt="power-gen-ai-copilot"
          src="/img/power-gen-ai-copilot-mobile.png"
          width="100%"
        />
        <div className="two-columns">
          <div>
            <Typography
              as="h2"
              $type="Heading1"
              $color={COLORS.WHITE}
              className="font-formula"
            >
              The Power of GenAI Copilots for Customer Support and Internal
              Productivity
            </Typography>
          </div>
          <div>
            <p>
              The use of generative AI tools for customer support has the
              potential to amplify customer satisfaction, enhance employees’
              decision-making processes, and elevate the productivity of
              Customer Service teams.
            </p>
            <p>
              Nowadays{' '}
              <span className="white bold">
                companies can have their own AI for customer support solutions
              </span>{' '}
              that studies your products and customers which then turns this
              knowledge into invaluable business insights in a way no human can.
            </p>
          </div>
        </div>
        <div className="three-columns">
          <div>
            <img
              alt="generative-ai-icon"
              src="/img/generative-ai-icon.png"
              width={120}
            />
            <h6>
              Around <span className="italic extra-bold purple">60%</span>{' '}
              <span className="italic extra-bold white">
                of businesses integrate generative AI
              </span>{' '}
              into their work environments.
            </h6>
          </div>
          <div>
            <img alt="absorb-icon" src="/img/absorb-icon.png" width={120} />
            <h6>
              Can automate work activities that{' '}
              <span className="extra-bold white italic">
                absorb <span className="blue">60%</span> to{' '}
                <span className="blue">70%</span> of employees’ time
              </span>{' '}
              today.
            </h6>
          </div>
          <div>
            <img alt="coin-icon" src="/img/coin-icon.png" width={120} />
            <h6>
              Generative AI copilots have the potential to{' '}
              <span className="extra-bold white italic">
                generate <span className="yellow">$2.6</span> trillion to{' '}
                <span className="yellow">$4.4</span> trillion
              </span>{' '}
              in value across all industries.{' '}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
