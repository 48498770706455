import React, { useRef, useState } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { clsx } from 'clsx';
import { SmallArrowWithoutRootIcon } from '@/assets/icons/smallArrowWithoutRootIcon';
import { motion } from 'framer-motion';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

type Props = {
  onValueChange?: (value: string) => void;
  classNameTabs?: string;
  classNameTabsList?: string;
  className?: string;
  current?: string;
  containerClassName?: string;
  isMobile?: boolean;
  filterContent: {
    id: string;
    activeColor?: string;
    text: React.JSX.Element;
  }[];
};

export const Filter = ({
  onValueChange = () => {},
  classNameTabs,
  classNameTabsList,
  filterContent,
  className,
  containerClassName,
  isMobile = true,
  current,
}: Props) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const onChangeHandler = (val: string) => {
    onValueChange(val);
    setOpen(false);
  };

  const clickOutsideHandler = () => {
    if (open) {
      setOpen(false);
    }
  };

  const filterHeight = 40 * filterContent.length;

  useOnClickOutside(ref, clickOutsideHandler);

  return (
    <div
      ref={ref}
      className={clsx(
        'max-md:mb-3 relative md:p-1-vw max-md:w-full w-fit bg-white rounded-[8px] shadow-xl md:justify-center justify-between items-center flex overflow-hidden',
        containerClassName,
      )}
    >
      {isMobile && !open && (
        <div
          className="h-full w-full absolute md:hidden cursor-pointer z-20"
          onClick={() => setOpen((prevState) => !prevState)}
        />
      )}
      <motion.div className={clsx('w-full h-full', className)}>
        <Tabs
          value={current}
          defaultValue="0"
          onValueChange={onChangeHandler}
          className={clsx('max-md:w-full', classNameTabs)}
        >
          <TabsList
            className={clsx(
              'md:gap-1.5-vw gap-1.5 md:h-auto bg-white max-md:flex max-md:w-full',
              isMobile && 'max-md:flex-col',
              !isMobile && 'h-full',
              classNameTabsList,
            )}
          >
            {filterContent.map((content) => {
              return (
                <TabsTrigger
                  key={content.id}
                  value={content.id}
                  className={clsx(
                    'data-[state=active]:shadow-md hover:bg-light max-md:w-full transition duration-300',
                    content.activeColor,
                    isMobile && !open && 'max-md:data-[state=inactive]:hidden',
                  )}
                >
                  {content.text}
                </TabsTrigger>
              );
            })}
          </TabsList>
        </Tabs>
      </motion.div>
      {isMobile && (
        <motion.div
          onClick={() => setOpen((prevState) => !prevState)}
          initial={false}
          animate={{
            scale: 1,
            height: open ? `${filterHeight}px` : '40px',
          }}
          transition={{
            duration: 0.3,
          }}
          className={clsx(
            'md:hidden p-2 h-full flex justify-between flex-col gap-1.5 transition cursor-pointer',
          )}
        >
          <SmallArrowWithoutRootIcon />
          <SmallArrowWithoutRootIcon direction="down" />
        </motion.div>
      )}
    </div>
  );
};
