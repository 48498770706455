import { AnimationSequence } from 'framer-motion';

export const biAnimation: AnimationSequence = [
  ['#bi', { opacity: 0 }, { duration: 0 }],
  [
    '#bi #cennect-icon:nth-child(1)',
    { x: 'calc(100% + 14px)', y: 'calc(100% + 14px)' },
    { duration: 0 },
  ],
  [
    '#bi #cennect-icon:nth-child(2)',
    { x: 'calc(-100% - 14px)', y: '0%' },
    { duration: 0 },
  ],
  [
    '#bi #cennect-icon:nth-child(3)',
    { x: 'calc(-200% - 28px)', y: 'calc(100% + 14px)' },
    { duration: 0 },
  ],
  [
    '#bi #cennect-icon:nth-child(4)',
    { x: 'calc(200% + 28px)', y: 'calc(-100% + 7px)' },
    { duration: 0 },
  ],
  [
    '#bi #cennect-icon:nth-child(5)',
    { x: 'calc(100% + 14px)', y: '0%' },
    { duration: 0 },
  ],
  [
    '#bi #cennect-icon:nth-child(6)',
    { x: 'calc(-100% - 14px)', y: 'calc(-100% + 7px)' },
    { duration: 0 },
  ],
  ['#bi', { opacity: 1 }, { duration: 0.3 }],
  [
    '#bi #cennect-icon:nth-child(1)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#bi #cennect-icon:nth-child(2)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#bi #cennect-icon:nth-child(3)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#bi #cennect-icon:nth-child(4)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#bi #cennect-icon:nth-child(5)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#bi #cennect-icon:nth-child(6)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
];

export const biAnimationOut: AnimationSequence = [
  ['#bi', { opacity: 0 }, { duration: 0 }],
];

export const customerAnimation: AnimationSequence = [
  ['#customer', { opacity: 0 }, { duration: 0 }],
  [
    '#customer #cennect-icon:nth-child(1)',
    { x: '0%', y: 'calc(100% + 14px)' },
    { duration: 0 },
  ],
  [
    '#customer #cennect-icon:nth-child(2)',
    { x: 'calc(100% + 14px)', y: '0%' },
    { duration: 0 },
  ],
  [
    '#customer #cennect-icon:nth-child(3)',
    { x: 'calc(-200% - 28px)', y: '0%' },
    { duration: 0 },
  ],
  [
    '#customer #cennect-icon:nth-child(4)',
    { x: 'calc(100% + 14px)', y: 'calc(-100% - 14px)' },
    { duration: 0 },
  ],
  [
    '#customer #cennect-icon:nth-child(5)',
    { x: 'calc(100% + 14px)', y: '0%' },
    { duration: 0 },
  ],
  [
    '#customer #cennect-icon:nth-child(6)',
    { x: 'calc(-100% - 14px)', y: '0%' },
    { duration: 0 },
  ],
  ['#customer', { opacity: 1 }, { duration: 0.3 }],
  [
    '#customer #cennect-icon:nth-child(1)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#customer #cennect-icon:nth-child(2)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#customer #cennect-icon:nth-child(3)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#customer #cennect-icon:nth-child(4)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#customer #cennect-icon:nth-child(5)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#customer #cennect-icon:nth-child(6)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
];

export const customerAnimationOut: AnimationSequence = [
  ['#customer', { opacity: 0 }, { duration: 0 }],
];

export const agentAnimation: AnimationSequence = [
  ['#agent', { opacity: 1 }, { duration: 0 }],
  [
    '#agent #cennect-icon:nth-child(1)',
    { x: 'calc(200% + 28px)', y: '0%' },
    { duration: 0 },
  ],
  [
    '#agent #cennect-icon:nth-child(2)',
    { x: 'calc(-100% - 14px)', y: '0%' },
    { duration: 0 },
  ],
  [
    '#agent #cennect-icon:nth-child(3)',
    { x: '0%', y: 'calc(100% + 14px)' },
    { duration: 0 },
  ],
  [
    '#agent #cennect-icon:nth-child(4)',
    { x: 'calc(100% + 14px)', y: '0%' },
    { duration: 0 },
  ],
  [
    '#agent #cennect-icon:nth-child(5)',
    { x: '0%', y: 'calc(-100% - 14px)' },
    { duration: 0 },
  ],
  [
    '#agent #cennect-icon:nth-child(6)',
    { x: 'calc(-200% - 28px)', y: '0%' },
    { duration: 0 },
  ],
  ['#agent', { opacity: 1 }, { duration: 0.3 }],
  [
    '#agent #cennect-icon:nth-child(1)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#agent #cennect-icon:nth-child(2)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#agent #cennect-icon:nth-child(3)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#agent #cennect-icon:nth-child(4)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#agent #cennect-icon:nth-child(5)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
  [
    '#agent #cennect-icon:nth-child(6)',
    { x: '0%', y: '0%' },
    { duration: 0.3, at: '-0.3' },
  ],
];

export const agentAnimationOut: AnimationSequence = [
  ['#agent', { opacity: 0 }, { duration: 0 }],
];
