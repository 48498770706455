import style from './Loader.module.css';
import React from 'react';
import { clsx } from 'clsx';

type LoaderProps = {
  className?: string;
};
export const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={clsx(className, 'fixed', style.loader)}>
      <svg
        className={style.blinker}
        xmlns="http://www.w3.org/2000/svg"
        width="117"
        height="75"
        viewBox="0 0 117 75"
        fill="none"
      >
        <path
          d="M36.5521 -7.92373e-05C26.92 -7.88072e-05 17.4481 3.95014 10.6372 10.9816C3.82633 18.013 -1.30276e-06 27.5497 -1.60443e-06 37.4936C-1.90611e-06 47.4376 3.82633 56.9742 10.6372 64.0056C17.4481 71.0371 26.92 74.9873 36.5521 74.9873L36.5521 37.4936L36.5521 -7.92373e-05Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.683 75.0001C100.741 75.0001 117.001 58.2136 117.001 37.5064C117.001 16.7992 100.741 0.0126953 80.683 0.0126953C60.6252 0.0126953 44.3652 16.7992 44.3652 37.5064C44.3652 58.2136 60.6252 75.0001 80.683 75.0001ZM80.6699 52.8939C88.884 52.8939 95.5429 46.0194 95.5429 37.5394C95.5429 29.0593 88.884 22.1849 80.6699 22.1849C72.4557 22.1849 65.7969 29.0593 65.7969 37.5394C65.7969 46.0194 72.4557 52.8939 80.6699 52.8939Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
