import React from 'react';
import { motion } from 'framer-motion';

type Props = {
  selectedFilter: number;
};

const images = [
  './img/connect-cosupport-bi.png',
  './img/connect-cosupport-customer.png',
  './img/connect-cosupport-agent.png',
];
export const ImagePart = ({ selectedFilter }: Props) => {
  return (
    <div className="image-container md:w-1/2 w-full md:p-[30px]">
      <div className="overflow-hidden h-full w-full md:rounded-xl relative max-md:min-h-[420px] min-h-[44.444vw]">
        {images.map((image, index) => (
          <div key={image} className="wrapper h-full w-full absolute">
            <motion.img
              className="h-full object-cover"
              animate={{
                opacity: selectedFilter === index ? 1 : 0,
              }}
              transition={{ duration: 0.6 }}
              src={image}
              width="100%"
              alt="An AI Business Analyst for informed decision-making and business insights"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
