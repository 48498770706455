export const MessageIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2.974C17 1.86943 16.1046 0.973999 15 0.973999L2.00023 0.973998C0.895655 0.973998 0.000225028 1.86943 0.00022498 2.974L0.000224521 13.5096C0.000224473 14.6141 0.895654 15.5096 2.00022 15.5096L6.26474 15.5096L8.57869 17.9444L10.8926 15.5096L15 15.5096C16.1046 15.5096 17 14.6141 17 13.5096L17 2.974Z"
        fill="#05050D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8649 8.93693C14.3054 8.49524 14.3054 7.77913 13.8649 7.33745C13.4245 6.89576 12.7103 6.89576 12.2698 7.33745C11.8293 7.77913 11.8293 8.49524 12.2698 8.93693C12.7103 9.37861 13.4245 9.37861 13.8649 8.93693ZM9.1933 7.33746C9.63366 7.77927 9.63366 8.49559 9.1933 8.9374C8.75293 9.37921 8.03896 9.37921 7.5986 8.9374C7.15823 8.49559 7.15823 7.77927 7.5986 7.33746C8.03896 6.89565 8.75293 6.89565 9.1933 7.33746ZM4.77924 7.33722C5.2196 7.77903 5.2196 8.49535 4.77923 8.93716C4.33887 9.37897 3.6249 9.37897 3.18453 8.93716C2.74417 8.49534 2.74417 7.77903 3.18453 7.33722C3.6249 6.89541 4.33887 6.89541 4.77924 7.33722Z"
        fill="#A498FF"
      />
    </svg>
  );
};
