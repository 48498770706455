export const CoSupportIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="6" fill="#DFF800" />
      <path
        d="M19.3721 16.0008C16.9024 16.0008 14.4738 16.9805 12.7274 18.7244C10.9811 20.4683 10 22.8335 10 25.2997C10 27.7659 10.9811 30.1311 12.7274 31.875C14.4738 33.6189 16.9024 34.5986 19.3721 34.5986L19.3721 25.2997L19.3721 16.0008Z"
        fill="#05050D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.687 34.5979C35.8298 34.5979 39.9989 30.4346 39.9989 25.2989C39.9989 20.1633 35.8298 16 30.687 16C25.5441 16 21.375 20.1633 21.375 25.2989C21.375 30.4346 25.5441 34.5979 30.687 34.5979ZM30.6885 29.1157C32.7947 29.1157 34.502 27.4108 34.502 25.3076C34.502 23.2044 32.7947 21.4995 30.6885 21.4995C28.5824 21.4995 26.8751 23.2044 26.8751 25.3076C26.8751 27.4108 28.5824 29.1157 30.6885 29.1157Z"
        fill="#05050D"
      />
    </svg>
  );
};
