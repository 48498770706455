type Props = {
  direction?: 'up' | 'down';
  width?: string;
  height?: string;
};

export const SmallArrowWithoutRootIcon = ({
  direction = 'up',
  height = '8',
  width = '12',
}: Props) => {
  if (direction === 'up') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.813 6.94967L5.86328 1.99992L0.913534 6.94967"
          stroke="black"
          strokeOpacity="0.3"
          strokeWidth="2"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.913533 0.849161L5.86328 5.79891L10.813 0.849162"
          stroke="black"
          strokeOpacity="0.3"
          strokeWidth="2"
        />
      </svg>
    );
  }
};
