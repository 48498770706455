import React, { useEffect, useRef } from 'react';
import { SuperChargeBlock } from './SuperChargeBlock';
import { PatentScrollBlock } from './PatentScrollBlock';
import { FAQMainPage } from '@/components/PagesSections/MainPageSections/FAQMainPage/FAQMainPage';

export const PatentSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5;
    }
  }, [videoRef]);

  return (
    <div className="relative bg-white">
      <section className="patent">
        <PatentScrollBlock />
        <FAQMainPage />
        <SuperChargeBlock />
      </section>
    </div>
  );
};
