import React, { useEffect, useRef } from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';

export const BusinessNeedsSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1;
    }
  }, [videoRef]);
  return (
    <div className="bg-ebony relative md:bg-[radial-gradient(74.42%_35.64%_at_50%_0%,#0E2D2B_0%,#05050D_100%)] bg-no-repeat">
      <Container className="md:pt-16-vw pt-10 md:pb-16-vw pb-10">
        <section className="business-needs container-global md:gap-5-vw gap-5">
          <Typography
            as="h2"
            $type="Heading1"
            className="font-formula text-center"
            $color={COLORS.WHITE}
          >
            CoSupport™ AI uses context from your{' '}
            <br className="max-md:hidden" /> actual data for all of your
            business needs.
          </Typography>
          <Typography $type="Heading4">
            Be the first to transform customer support into a{' '}
            <span className="bold white">
              magical hub of Business Intelligence
            </span>{' '}
            and ideas without the need for additional staff.
          </Typography>
          <video
            className="md:block hidden"
            data-src="./video/business_needs_scheme"
            autoPlay
            muted
            playsInline
            loop
            disablePictureInPicture
            width="100%"
            poster="/img/business-needs.png"
          >
            <source
              src={'/video/business_needs_scheme_mobile.mp4'}
              type="video/mp4"
              media="(max-width: 768px)"
            />
            <source
              src={'/video/business_needs_scheme.webm'}
              type="video/webm"
              media="(min-width: 768px)"
            />
          </video>
          <img
            className="md:hidden block"
            src="/img/business_needs_scheme_mobile.gif"
            width="100%"
          />
        </section>
      </Container>
    </div>
  );
};
