'use client';
import React, { useState, useEffect, useRef, ReactElement } from 'react';
import {
  motion,
  AnimatePresence,
  useIsPresent,
  useInView,
} from 'framer-motion';
import { clsx } from 'clsx';

type commentsType = {
  id: number;
  internalId: number;
  comment: ReactElement;
};

type propsType = {
  comments: commentsType[];
  className?: string;
};

export const TrackComponent = ({ comments, className }: propsType) => {
  const [slides, setSlides] = useState<commentsType[]>([]);
  const ref = useRef(null);
  const isInView = useInView(ref);
  const isInViewMargin = useInView(ref, { margin: '1000px' });

  const handlePushNewSlide = () => {
    setSlides((prevSlides) => {
      const newSlides = [...prevSlides];
      const shiftedItem: commentsType = newSlides.shift() as commentsType;
      newSlides.push({
        ...comments[shiftedItem.internalId as number],
        id: shiftedItem.id + 11.123,
      });
      return newSlides;
    });
  };

  const interval = useRef<any>(null);

  useEffect(() => {
    if (isInView && slides.length === 0) {
      setSlides([comments[0]]);
    }
  }, [isInView]);

  useEffect(() => {
    if (isInViewMargin) {
      interval.current = setInterval(() => {
        const nextIndex = slides.length;
        if (nextIndex < comments.length) {
          setSlides((prevSlides) => [...prevSlides, comments[nextIndex]]);
        } else {
          handlePushNewSlide();
        }
      }, 6000);
    } else {
      clearInterval(interval.current);
    }

    return () => clearInterval(interval.current);
  }, [slides, isInViewMargin, comments]);

  return (
    <div ref={ref} className={clsx(className, 'h-full w-full')}>
      <div className="slider-track">
        <div className="slider-list h-full flex flex-col md:gap-2.5-vw gap-1.5 relative justify-end -mt-4 overflow-hidden">
          <AnimatePresence>
            {slides.map((item) => (
              <Item key={item.id}>{item.comment}</Item>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

type ItemType = {
  children: React.ReactNode;
};
const Item = ({ children }: ItemType) => {
  const isPresent = useIsPresent();
  const animations = {
    position: isPresent ? 'static' : 'absolute',
    initial: { transform: 'translateY(120%)' },
    animate: { transform: 'translateY(0%)' },
    transition: {
      ease: 'easeInOut',
      duration: 0.7,
      delay: 1,
    },
  };
  return (
    <motion.div {...animations} layout>
      {children}
    </motion.div>
  );
};
