import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { MessageIcon } from '@/assets/icons/messageIcon';
import { HeadphonesIcon } from '@/assets/icons/headphonesIcon';
import { StarIcon } from '@/assets/icons/starIcon';
import { ImageScrollCardIcon } from '@/assets/icons/ImageScrollCardIcon';
import { useRouter } from 'next/router';
import { PATH_AGENT, PATH_BI, PATH_CUSTOMER } from '@/constants/spa-routes';

export const ImageScroll = () => {
  const { push } = useRouter();

  return (
    <>
      <Container className="image-scroll">
        <div className="rounded-3xl overflow-hidden">
          <img
            className="hover:scale-110 transition duration-500 md:min-w-[90vw] md:object-fill md:w-full"
            src="/img/image-scroll.png"
            alt="CoSupport AI enhances customer support and empowers businesses with data-driven insights"
          />
        </div>
      </Container>
      <Container className="image-scroll-card md:min-h-[38.542vw]">
        <div
          className="image-scroll-card-content"
          onClick={() => push(PATH_CUSTOMER.ROOT)}
        >
          <div className="image-scroll-card-header absolute top-0 w-full left-0 bg-purple md:px-5-vw px-5 md:py-3.5-vw py-3 flex items-center md:gap-2.5-vw gap-2.5">
            <div className="max-md:max-w-16 max-md:max-h-16">
              <MessageIcon className="md:w-[1.181vw] md:h-[1.181vw]" />
            </div>
            <Typography $type="PLittle" $weight="600" className="uppercase">
              customer service automatisation
            </Typography>
          </div>
          <div className="body-wrapper rounded-[20px] md:px-17-vw md:pt-[8.333vw] pt-[93px] px-4 min-h-[400px] md:pb-[4.25vw] md:max-w-[38.194vw] relative overflow-hidden">
            <div className="body">
              <Typography as="h5" $type="PRegular">
                <span className="italic extra-bold">
                  Are the number of requests wearing down your agents?
                </span>{' '}
              </Typography>
              <Typography as="h3" $type="Heading2">
                AI-Powered Response Automation
              </Typography>
              <Typography $type="Heading4">
                Delegate up to 100% of requests to our AI assistants. Decide
                what fraction of requests to address to AI. The rules are yours.
              </Typography>
            </div>
            <div className={'arrow-icon'}>
              <ImageScrollCardIcon />
            </div>
          </div>
        </div>
      </Container>
      <Container className="image-scroll-card md:min-h-[38.542vw]">
        <div
          className="image-scroll-card-content"
          onClick={() => push(PATH_AGENT.ROOT)}
        >
          <div className="image-scroll-card-header absolute top-0 w-full left-0 bg-malibu md:px-5-vw px-5 md:py-3.5-vw py-3 flex items-center md:gap-2.5-vw gap-2.5">
            <div className="max-md:max-w-16 max-md:max-h-16">
              <HeadphonesIcon className="md:w-[1.181vw] md:h-[1.181vw]" />
            </div>
            <Typography $type="PLittle" $weight="600" className="uppercase">
              Support agent assistant
            </Typography>
          </div>
          <div className="body-wrapper rounded-[20px] md:px-17-vw md:pt-[8.333vw] pt-[93px] px-4 min-h-[400px] md:pb-[4.25vw] md:max-w-[39.583vw] relative overflow-hidden">
            <div className="body">
              <Typography as="h5" $type="PRegular">
                <span className="italic extra-bold">
                  Does your team fail to answer at a break-neck speed?
                </span>{' '}
              </Typography>
              <Typography as="h3" $type="Heading2">
                Patented Response Acceleration Technology
              </Typography>
              <Typography $type="Heading4">
                Benefit from our AI assistant response speed. CoSupport Agent
                responds in milliseconds while GPT needs 3-5 seconds for
                request.
              </Typography>
            </div>
            <div className={'arrow-icon'}>
              <ImageScrollCardIcon />
            </div>
          </div>
        </div>
      </Container>
      <Container className="image-scroll-card md:min-h-[38.542vw] md:mb-24-vw">
        <div
          className="image-scroll-card-content"
          onClick={() => push(PATH_BI.ROOT)}
        >
          <div className="image-scroll-card-header absolute top-0 w-full left-0 bg-chartreuse_yellow md:px-5-vw px-5 md:py-3.5-vw py-3 flex items-center md:gap-2.5-vw gap-2.5">
            <div className="max-md:max-w-16 max-md:max-h-16">
              <StarIcon className="md:w-[1.181vw] md:h-[1.181vw]" />
            </div>
            <Typography $type="PLittle" $weight="600" className="uppercase">
              personal business copilot
            </Typography>
          </div>
          <div className="body-wrapper rounded-[20px] md:px-17-vw md:pt-[8.333vw] pt-[93px] px-4 min-h-[400px] md:pb-[4.25vw] md:max-w-[40.972vw] relative overflow-hidden">
            <div className="body">
              <Typography as="h5" $type="PRegular">
                <span className="italic extra-bold">
                  Eager to see areas of improvement clearly?
                </span>
              </Typography>
              <Typography as="h3" $type="Heading2">
                BI Copilot for the Helicopter View
              </Typography>
              <Typography $type="Heading4">
                Ask AI simple questions and get insights to make decisions on
                complex issues. Ground your strategy on approved insights.
              </Typography>
            </div>
            <div className={'arrow-icon'}>
              <ImageScrollCardIcon />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
