export const HeadphonesIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2578 12.8999V6.758C14.2578 3.57795 11.6799 1 8.49982 1V1C5.31977 1 2.74182 3.57794 2.74182 6.758V14.5613C2.74182 15.5215 3.52023 16.2999 4.48045 16.2999H6.74739"
        stroke="#05050D"
        strokeWidth="2"
      />
      <path
        d="M2.24609 7.16672H2.00419C1.17576 7.16672 0.504191 7.83829 0.504191 8.66672V10.9C0.504191 11.7285 1.17576 12.4 2.00419 12.4H2.24609V7.16672Z"
        fill="#05050D"
        stroke="#05050D"
      />
      <path
        d="M14.7578 12.4006L14.9997 12.4006C15.8281 12.4006 16.4997 11.729 16.4997 10.9006L16.4997 8.66731C16.4997 7.83889 15.8281 7.16731 14.9997 7.16731L14.7578 7.16731L14.7578 12.4006Z"
        fill="#05050D"
        stroke="#05050D"
      />
      <path
        d="M6.53394 16.3002C6.53394 16.9785 7.06196 17.5002 7.67908 17.5002C8.29619 17.5002 8.82422 16.9785 8.82422 16.3002C8.82422 15.6218 8.29619 15.1002 7.67908 15.1002C7.06196 15.1002 6.53394 15.6218 6.53394 16.3002Z"
        fill="#05050D"
        stroke="#05050D"
      />
    </svg>
  );
};
