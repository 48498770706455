import React, { ReactNode } from 'react';
import {
  Typography,
  TypographyAs,
  TypographyType,
} from '@/components/Typography/Typography';
import { AnimatePresence, motion } from 'framer-motion';

type TextSwitchProps = {
  className?: string;
  typographyClassName?: string;
  fontSizeXs?: string;
  as?: TypographyAs;
  type?: TypographyType;
  weight?: string;
  currentSlide: number;
  texts: ReactNode[];
};

export const TextSwitch = ({
  className = '',
  typographyClassName = '',
  currentSlide,
  texts,
  as = 'h3',
  fontSizeXs,
  type = 'Heading4',
  weight = '700',
}: TextSwitchProps) => {
  return (
    <AnimatePresence initial={false}>
      <motion.div
        className={className}
        key={currentSlide}
        layout
        variants={{
          enter: {
            opacity: 0,
            position: 'absolute',
          },
          center: {
            opacity: 1,
            position: 'relative',
          },
          exit: {
            opacity: 0,
            position: 'absolute',
          },
        }}
        initial="enter"
        animate="center"
        exit="exit"
      >
        <Typography
          as={as}
          $type={type}
          $weight={weight}
          className={typographyClassName}
          $fontSizeXs={fontSizeXs}
        >
          {texts[currentSlide]}
        </Typography>
      </motion.div>
    </AnimatePresence>
  );
};
