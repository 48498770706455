import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { useMediaQuery } from '@/hooks/useMediaQuery';

export const PatentScrollBlock = () => {
  const desktop = useMediaQuery('(min-width: 768px)');

  return (
    <>
      <div className="patent-block">
        <Container className="sticky top-[60px] max-md:p-0">
          <div className="flex justify-between md:flex-row flex-col md:rounded-[20px] bg-light">
            <div className="patent-content">
              <div className="content md:pl-10 px-6 pt-14 md:py-11">
                <div className="title">
                  <Typography as="h3" $type="Heading2">
                    CoSupport AI Achieved the Patent for Its AI for Customer
                    Support Solutions That Innovate Business Operations
                  </Typography>
                </div>
                <Typography $type="PMedium">
                  CoSupport™ AI's Patented AI Message Generation Technology
                  delivers swift, precise, and cost-effective solutions,
                  ensuring top-notch data security. Our patented, specialized AI
                  architecture is specifically designed for customer support,
                  offering unparalleled efficiency and accuracy in response
                  times.
                </Typography>
                <Typography $type="PMedium">
                  This innovation has been officially recognized by the United
                  States Patent and Trademark Office as a transformative leap
                  forward, offering unparalleled advantages for customer support
                  operations.
                </Typography>
              </div>
            </div>
            <div className="patent-image-container lg:pr-20 p-6">
              <img
                className="desktop"
                src="./img/patent-image-desktop.png"
                height="100%"
              />
              <img
                className="mobile"
                src="./img/patent-image-mobile.png"
                width="100%"
              />
            </div>
          </div>
        </Container>
      </div>
      {desktop ? (
        <>
          <div className="patent-card">
            <div className="patent-card-title">
              <svg
                className="md:h-[1.181vw] md:w-[1.042vw]"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
              >
                <g clipPath="url(#clip0_1128_50227)">
                  <path
                    d="M14.1172 10.3824C14.1172 14.0372 11.1544 17 7.49954 17C3.84471 17 0.881892 14.0372 0.881893 10.3824L0.881895 3.27259e-07L7.49954 1.6363e-07L14.1172 1.90735e-06L14.1172 10.3824Z"
                    fill="#05050D"
                  />
                  <ellipse
                    cx="7.4996"
                    cy="9.84193"
                    rx="2.64706"
                    ry="2.68421"
                    fill="#A498FF"
                  />
                  <path
                    d="M9.7057 5.36819C9.7057 4.77494 9.4733 4.20599 9.05962 3.7865C8.64594 3.36701 8.08486 3.13135 7.49983 3.13135C6.91479 3.13135 6.35372 3.36701 5.94003 3.7865C5.52635 4.20599 5.29395 4.77494 5.29394 5.36819L7.49982 5.36819L9.7057 5.36819Z"
                    fill="#A498FF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1128_50227">
                    <rect width="15" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography $type="PSmall">DATA SECURITY</Typography>
            </div>
            <div className="patent-card-body">
              <Typography as="h4">
                CoSupport™ Agent{' '}
                <span className="extra-bold italic dark">
                  uses client-specific proprietary data and avoids external
                  databases
                </span>
                , greatly reducing data vulnerability compared to LLMs like
                GPT-3.5.
              </Typography>
              <Typography $type="PMedium">
                Trained on anonymized data, it minimizes the risk of personal
                data leaks.
              </Typography>
            </div>
          </div>
          <div className="patent-card">
            <div className="patent-card-title">
              <svg
                className="md:h-[1.042vw] md:w-[1.042vw]"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <g clipPath="url(#clip0_1202_51651)">
                  <rect width="15" height="15" fill="#75F3FB" />
                  <ellipse cx="7.5" cy="7.5" rx="7.5" ry="7.5" fill="#05050D" />
                  <path
                    d="M7.05469 4.36279L7.05469 7.89225L10.5841 7.89225"
                    stroke="#75F3FB"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1202_51651">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography $type="PSmall">A QUICK RESPONSE TIME</Typography>
            </div>
            <div className="patent-card-body">
              <Typography as="h4">
                CoSupport™ Agent, featuring patented AI architecture,{' '}
                <span className="extra-bold italic dark">
                  responds to individual queries in milliseconds
                </span>
                , compared to ChatGPT's 2-3 seconds.
              </Typography>
              <Typography $type="PMedium">
                In message threads, it replies within 5 seconds, significantly
                faster than ChatGPT's response time of over 15 seconds.
              </Typography>
            </div>
          </div>
          <div className="patent-card">
            <div className="patent-card-title">
              <svg
                className="md:h-[1.042vw] md:w-[1.042vw]"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <g clipPath="url(#clip0_7877_10811)">
                  <rect width="15" height="15" fill="#DFF800" />
                  <path d="M0 8H15V11H0V8Z" fill="#05050D" />
                  <path d="M0 4H15V7H0V4Z" fill="#05050D" />
                  <path d="M0 0H15V3H0V0Z" fill="#05050D" />
                  <path d="M0 12H15V15H0V12Z" fill="#05050D" />
                </g>
                <defs>
                  <clipPath id="clip0_7877_10811">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography $type="PSmall">COST-EFFECTIVENESS</Typography>
            </div>
            <div className="patent-card-body">
              <Typography as="h4">
                Less resource-intensive models offer{' '}
                <span className="extra-bold italic dark">
                  greater cost-effectiveness
                </span>{' '}
                compared to larger LLMs, leading to lower operational costs.
              </Typography>
              <Typography $type="PMedium">
                Its pricing is straightforward and transparent, featuring a
                one-time fee for a ready-to-use AI product, in contrast to the
                complex pricing structures of larger AI platforms like OpenAI.
              </Typography>
            </div>
          </div>
          <div className="patent-card">
            <div className="patent-card-title">
              <svg
                className="md:h-[1.042vw] md:w-[1.042vw]"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <g clipPath="url(#clip0_7877_10829)">
                  <rect width="15" height="15" fill="#33FF71" />
                  <circle cx="7.5" cy="7.5" r="7.5" fill="#05050D" />
                  <circle cx="7.5" cy="7.5" r="7.5" fill="#05050D" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 5.5524L7.29329 9.4132L7.28424 9.40394L6.23138 10.5L4 8.17465L4.93777 7.19651L6.17018 8.48096L9.98983 4.5L11 5.5524Z"
                    fill="#33FF71"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7877_10829">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography $type="PSmall">HIGH PRECISION</Typography>
            </div>
            <div className="patent-card-body">
              <Typography as="h4">
                By training on specialized datasets tailored to specific company
                products and services, enabling deeper conversation context
                understanding and{' '}
                <span className="extra-bold italic dark">
                  accurate responses, unlike broader LLMs.
                </span>
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <div className="patent-cards mobile">
          <div className="patent-horizontal">
            <div className="patent-card-mobile mobile">
              <div className="patent-card-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1128_50227)">
                    <path
                      d="M14.1172 10.3824C14.1172 14.0372 11.1544 17 7.49954 17C3.84471 17 0.881892 14.0372 0.881893 10.3824L0.881895 3.27259e-07L7.49954 1.6363e-07L14.1172 1.90735e-06L14.1172 10.3824Z"
                      fill="#05050D"
                    />
                    <ellipse
                      cx="7.4996"
                      cy="9.84193"
                      rx="2.64706"
                      ry="2.68421"
                      fill="#A498FF"
                    />
                    <path
                      d="M9.7057 5.36819C9.7057 4.77494 9.4733 4.20599 9.05962 3.7865C8.64594 3.36701 8.08486 3.13135 7.49983 3.13135C6.91479 3.13135 6.35372 3.36701 5.94003 3.7865C5.52635 4.20599 5.29395 4.77494 5.29394 5.36819L7.49982 5.36819L9.7057 5.36819Z"
                      fill="#A498FF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1128_50227">
                      <rect width="15" height="17" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Typography $type="PSmall">DATA SECURITY</Typography>
              </div>
              <div className="patent-card-body">
                <Typography as="h4">
                  CoSupport™ Agent{' '}
                  <span className="extra-bold italic dark">
                    uses client-specific proprietary data and avoids external
                    databases
                  </span>
                  , greatly reducing data vulnerability compared to LLMs like
                  GPT-3.5.
                </Typography>
                <Typography $type="PMedium">
                  Trained on anonymized data, it minimizes the risk of personal
                  data leaks.
                </Typography>
              </div>
            </div>
            <div className="patent-card-mobile mobile">
              <div className="patent-card-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1202_51651)">
                    <rect width="15" height="15" fill="#75F3FB" />
                    <ellipse
                      cx="7.5"
                      cy="7.5"
                      rx="7.5"
                      ry="7.5"
                      fill="#05050D"
                    />
                    <path
                      d="M7.05469 4.36279L7.05469 7.89225L10.5841 7.89225"
                      stroke="#75F3FB"
                      strokeWidth="1.5"
                      strokeLinecap="square"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1202_51651">
                      <rect width="15" height="15" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Typography $type="PSmall">A QUICK RESPONSE TIME</Typography>
              </div>
              <div className="patent-card-body">
                <Typography as="h4">
                  CoSupport™ Agent, featuring patented AI architecture,{' '}
                  <span className="extra-bold italic dark">
                    responds to individual queries in milliseconds
                  </span>
                  , compared to ChatGPT's 2-3 seconds.
                </Typography>
                <Typography $type="PMedium">
                  In message threads, it replies within 5 seconds, significantly
                  faster than ChatGPT's response time of over 15 seconds.
                </Typography>
              </div>
            </div>
            <div className="patent-card-mobile mobile">
              <div className="patent-card-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <g clipPath="url(#clip0_8093_9316)">
                    <rect width="15" height="15" fill="#DFF800" />
                    <path d="M0 8H15V11H0V8Z" fill="#05050D" />
                    <path d="M0 4H15V7H0V4Z" fill="#05050D" />
                    <path d="M0 0H15V3H0V0Z" fill="#05050D" />
                    <path d="M0 12H15V15H0V12Z" fill="#05050D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_8093_9316">
                      <rect width="15" height="15" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>COST-EFFECTIVENESS</p>
              </div>
              <div className="patent-card-body">
                <Typography as="h4">
                  Less resource-intensive models offer{' '}
                  <span className="extra-bold italic dark">
                    greater cost-effectiveness
                  </span>{' '}
                  compared to larger LLMs, leading to lower operational costs.
                </Typography>
                <Typography $type="PMedium">
                  Its pricing is straightforward and transparent, featuring a
                  one-time fee for a ready-to-use AI product, in contrast to the
                  complex pricing structures of larger AI platforms like OpenAI.
                </Typography>
              </div>
            </div>
            <div className="patent-card-mobile mobile">
              <div className="patent-card-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <g clipPath="url(#clip0_8093_9332)">
                    <rect width="15" height="15" fill="#33FF71" />
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#05050D" />
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#05050D" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 5.5524L7.29329 9.4132L7.28424 9.40394L6.23138 10.5L4 8.17465L4.93777 7.19651L6.17018 8.48096L9.98983 4.5L11 5.5524Z"
                      fill="#33FF71"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8093_9332">
                      <rect width="15" height="15" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Typography $type="PSmall">HIGH PRECISION</Typography>
              </div>
              <div className="patent-card-body">
                <Typography as="h4">
                  By training on specialized datasets tailored to specific
                  company products and services, enabling deeper conversation
                  context understanding and{' '}
                  <span className="extra-bold italic dark">
                    accurate responses, unlike broader LLMs.
                  </span>
                </Typography>
              </div>
            </div>
          </div>
          <div className="patent-control">
            <fieldset>
              <input
                type="radio"
                name="patent-control"
                value="0"
                defaultChecked
              />
              <input type="radio" name="patent-control" value="1" />
              <input type="radio" name="patent-control" value="2" />
              <input type="radio" name="patent-control" value="3" />
            </fieldset>
          </div>
        </div>
      )}
    </>
  );
};
