type Props = {
  className?: string;
  color?: string;
};

export const LinkArrowIcon = ({ className, color = '#DFF800' }: Props) => {
  return (
    <svg
      className={className}
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8984 4L11.8984 6.11959e-07L18.8984 7L11.8984 14L11.8984 9.9C6.89844 9.9 3.39844 11.5 0.898439 15C1.89844 10 4.89844 5 11.8984 4Z"
        fill={color}
      />
    </svg>
  );
};
