import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';

export const GetStartedSection = () => {
  return (
    <div className="relative bg-white">
      <Container className="md:py-[6.944vw] py-[60px]">
        <section className="get-started max-md:flex max-md:flex-col max-md:gap-8">
          <div className="w-full flex justify-center">
            <Typography
              $type="Heading1"
              as="h2"
              className="md:max-w-[55.083vw] md:text-center text-start font-formula"
            >
              Get started with your own AI for customer support in four easy
              steps
            </Typography>
          </div>
          <div className="steps">
            <div className="step desktop">
              <div className="md:h-[5.208vw] h-[50px] md:w-[6.597vw] w-[64px]">
                <img src="/img/mail-icon.png" alt="Email icon" />
              </div>
              <div>
                <Typography as="h4" $type="PMedium" $weight="800">
                  1. Request
                </Typography>
                <Typography $type="PMedium">
                  Request Demo & Quote today. We will receive your request
                  within minutes and get back to you.
                </Typography>
              </div>
              <div className="takes">
                <svg
                  className="md:h-4-vw md:w-[1.042vw]"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                >
                  <use xlinkHref="#get-started-takes-watch"></use>
                </svg>
                <Typography $type="PMedium" $weight="600">
                  Takes only 2 min.
                </Typography>
              </div>
            </div>
            <div className="step mobile">
              <div className="step-header">
                <div className="md:h-[5.208vw] h-[50px] md:w-[6.597vw] w-[64px]">
                  <img
                    className="max-h-[50px]"
                    src="/img/mail-icon.png"
                    alt="Email icon"
                  />
                </div>
                <div className="step-header-title">
                  <h4>1. Request</h4>
                  <div className="takes">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
                      <use xlinkHref="#get-started-takes-watch"></use>
                    </svg>
                    <p>Takes only 2 min.</p>
                  </div>
                </div>
              </div>
              <div>
                <p>
                  Request Demo & Quote today. We will receive your request
                  within minutes and get back to you.
                </p>
              </div>
            </div>
            <div className="step desktop">
              <div className="md:h-[5.208vw] h-[50px] md:w-[6.597vw] w-[64px]">
                <img src="/img/cubic-icon.png" alt="cubic icon" />
              </div>
              <div>
                <Typography as="h4" $type="PMedium" $weight="800">
                  2. Integration
                </Typography>
                <Typography $type="PMedium">
                  Our team will take a look at your data, structure it, train AI
                  models on it, and connect to your communication channels.
                </Typography>
              </div>
              <div className="takes">
                <svg
                  className="md:h-4-vw md:w-[1.042vw]"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                >
                  <use xlinkHref="#get-started-takes-calendar"></use>
                </svg>
                <Typography $type="PMedium" $weight="600">
                  Takes 2-4 weeks (approx)
                </Typography>
              </div>
            </div>
            <div className="step mobile">
              <div className="step-header">
                <div className="md:h-[5.208vw] h-[50px] md:w-[6.597vw] w-[64px]">
                  <img
                    className="max-h-[50px]"
                    src="/img/cubic-icon.png"
                    alt="cubic icon"
                  />
                </div>
                <div className="step-header-title">
                  <h4>2. Integration</h4>
                  <div className="takes">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
                      <use xlinkHref="#get-started-takes-calendar"></use>
                    </svg>
                    <p>Takes 2-4 weeks (approx)</p>
                  </div>
                </div>
              </div>
              <div>
                <p>
                  Our team will take a look at your data, structure it, train AI
                  models on it, and connect to your communication channels.
                </p>
              </div>
            </div>
            <div className="step desktop">
              <div className="md:h-[5.208vw] h-[50px] md:w-[6.597vw] w-[64px]">
                <img src="/img/settings-icon.png" alt="settings icon" />
              </div>
              <div>
                <Typography as="h4" $type="PMedium" $weight="800">
                  3. Testing
                </Typography>
                <Typography $type="PMedium">
                  Together with you we test it on your actual workflow and
                  fine-tune it for perfect results.
                </Typography>
              </div>
              <div className="takes">
                <svg
                  className="md:h-4-vw md:w-[1.042vw]"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                >
                  <use xlinkHref="#get-started-takes-calendar"></use>
                </svg>
                <Typography $type="PMedium" $weight="600">
                  Takes 1-2 weeks (approx)
                </Typography>
              </div>
            </div>
            <div className="step mobile">
              <div className="step-header">
                <div className="md:h-[5.208vw] h-[50px] md:w-[6.597vw] w-[64px]">
                  <img
                    className="max-h-[50px]"
                    src="/img/settings-icon.png"
                    alt="settings icon"
                  />
                </div>
                <div className="step-header-title">
                  <h4>3. Testing</h4>
                  <div className="takes">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
                      <use xlinkHref="#get-started-takes-calendar"></use>
                    </svg>
                    <p>Takes 1-2 weeks (approx)</p>
                  </div>
                </div>
              </div>
              <div>
                <p>
                  Together with you we test it on your actual workflow and
                  fine-tune it for perfect results.
                </p>
              </div>
            </div>
            <div className="step desktop">
              <div className="md:h-[5.208vw] h-[50px] md:w-[6.597vw] w-[64px]">
                <img src="/img/medal-icon.png" alt="medal icon" />
              </div>
              <div>
                <Typography as="h4" $type="PMedium" $weight="800">
                  4. Launch
                </Typography>
                <Typography $type="PMedium">
                  When you are satisfied with the test results: we launch it
                  live across your whole business. Take a snap of a finger!
                </Typography>
              </div>
              <div className="takes">
                <svg
                  className="md:h-4-vw md:w-[1.042vw]"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                >
                  <use xlinkHref="#get-started-takes-finger"></use>
                </svg>
                <Typography $type="PMedium" $weight="600">
                  Takes a snap of fingers!
                </Typography>
              </div>
            </div>
            <div className="step mobile">
              <div className="step-header">
                <div className="md:h-[5.208vw] h-[50px] md:w-[6.597vw] w-[64px]">
                  <img
                    className="max-h-[50px]"
                    src="/img/medal-icon.png"
                    alt="medal icon"
                  />
                </div>
                <div className="step-header-title">
                  <h4>4. Launch</h4>
                  <div className="takes">
                    <svg
                      className="md:h-4-vw md:w-[1.042vw]"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <use xlinkHref="#get-started-takes-finger"></use>
                    </svg>
                    <p>Takes a snap of fingers!</p>
                  </div>
                </div>
              </div>
              <div>
                <p>
                  When you are satisfied with the test results: we launch it
                  live across your whole business. Take a snap of a finger!
                </p>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};
