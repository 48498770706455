import React, { useState } from 'react';
import { TextBlock } from './TextBlock/TextBlock';
import { Container } from '@/components/Containers/Container';
import { clsx } from 'clsx';
import dynamic from 'next/dynamic';
import { PatentSolution } from '@/components/PatentSolution/PatentSolution';

type ContentProps = {
  className?: string;
};

const PresentationBlock = dynamic(() => import('./PresentationBlock'), {
  ssr: false,
});

export const Content = ({ className }: ContentProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <Container>
      <div
        className={clsx(
          'flex lg:gap-5-vw gap-8 lg:flex-row flex-col',
          className,
        )}
      >
        <TextBlock className="w-full grow" currentSlide={currentSlide} />
        <PresentationBlock
          className="w-full"
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
        />
      </div>
      <PatentSolution className="max-lg:flex lg:hidden mt-8" />
    </Container>
  );
};
