import React, { useEffect, useRef } from 'react';
import Link from 'next/link';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { BlackButton } from '@/components/ButtonsCollection/BlackButton/BlackButton';
import { COLORS } from '@/constants/colors';
import { PATH_AGENT, PATH_CUSTOMER } from '@/constants/spa-routes';
import { SmallArrowIcon } from '@/assets/icons/smallArrowIcon';
import { CoSupportBi } from './CoSupportBi';

export const HowSolutionHelp = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoRef2 = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1;
    }
    if (videoRef2.current) {
      videoRef2.current.playbackRate = 1;
    }
  }, [videoRef, videoRef2]);
  return (
    <div className="relative z-1 bg-white">
      <Container>
        <div className="how-solution-help md:pb-16-vw md:pt-16-vw">
          <Typography
            as="h2"
            $type="Heading1"
            className="md:max-w-[59.028vw] md:mb-8 font-formula text-center"
          >
            What benefits does each CoSupportTM AI copilot brings?
          </Typography>
          <div className="block-el w-full">
            <div>
              <div className="title">
                <Typography as="h4" $type="Heading2">
                  <span className="bold">CoSupport™ Customer</span>
                </Typography>
              </div>
              <div className="designed-for py-1 md:py-1-vw px-3 md:px-3-vw">
                <Typography $type="PSmall" $color={COLORS.GRAY_OPACITY}>
                  Designed for Customer Self Service
                </Typography>
              </div>
              <div className="text">
                <Typography $type="Heading4">
                  With CoSupport™ Customer your{' '}
                  <span className="dark bold">
                    human agents can focus on complex issues.
                  </span>{' '}
                  Our AI for customer support works seamlessly with your human
                  support staff and manages up to 70% of typical questions
                  across multiple channels.
                </Typography>
              </div>
              <div className="button-container">
                <Link href={PATH_CUSTOMER.ROOT}>
                  <BlackButton>
                    <div className="button-text flex justify-center items-center md:gap-4-vw gap-4">
                      <Typography
                        $color={COLORS.WHITE}
                        as="span"
                        $type="PRegular"
                        $weight="700"
                      >
                        Learn More
                      </Typography>
                      <SmallArrowIcon color="purple" />
                    </div>
                  </BlackButton>
                </Link>
              </div>
            </div>
            <div className="video-block">
              <video
                className="md:max-w-[34.722vw]"
                ref={videoRef}
                data-mobile="0"
                data-desktop="1"
                data-src="./video/how_can_help_customer"
                disablePictureInPicture
                autoPlay
                muted
                playsInline
                loop
                poster="/img/copilot-frame-1.png"
              >
                <source
                  src={'/video/how_can_help_customer.webm'}
                  type="video/webm"
                />
              </video>
            </div>
          </div>
          <div className="block-el w-full">
            <div className="video-block">
              <video
                className="md:max-w-[34.722vw]"
                ref={videoRef2}
                data-mobile="0"
                data-desktop="1"
                disablePictureInPicture
                autoPlay
                muted
                playsInline
                loop
                poster="/img/copilot-frame-2.png"
              >
                <source
                  src={'/video/how_can_help_agent.webm'}
                  type="video/webm"
                />
              </video>
            </div>
            <div>
              <div className="title">
                <Typography as="h4" $type="Heading2">
                  <span className="bold">CoSupport™ Agent</span>
                </Typography>
              </div>
              <div className="designed-for py-1 md:py-1-vw px-3 md:px-3-vw">
                <Typography $type="PSmall" $color={COLORS.GRAY_OPACITY}>
                  AI Agent Assistant Designed for Customer Support Teams
                </Typography>
              </div>
              <div className="text">
                <Typography $type="Heading4">
                  Now you can process{' '}
                  <span className="bold dark">requests faster and easier.</span>{' '}
                  With CoSupport™ Agent, your support team can process tickets{' '}
                  <span className="bold dark">30-80% faster.</span> You receive
                  the customer’s email and an AI agent assistant{' '}
                  <span className="bold dark">
                    generates unique reply suggestions
                  </span>{' '}
                  based on actual context.
                </Typography>
              </div>
              <div className="button-container">
                <Link href={PATH_AGENT.ROOT}>
                  <BlackButton>
                    <div className="button-text flex justify-center items-center md:gap-4-vw gap-4">
                      <Typography
                        $color={COLORS.WHITE}
                        as="span"
                        $type="PRegular"
                        $weight="700"
                      >
                        Learn More
                      </Typography>
                      <SmallArrowIcon color="blue" />
                    </div>
                  </BlackButton>
                </Link>
              </div>
            </div>
          </div>
          <CoSupportBi />
        </div>
      </Container>
    </div>
  );
};
