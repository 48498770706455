import React from 'react';
import { Typography } from '@/components/Typography/Typography';

type Props = {
  icon: {
    icon: string;
    helpText: string;
  };
};

export const IconComponent = ({ icon }: Props) => {
  return (
    <div
      id="cennect-icon"
      key={icon.icon}
      className="md:max-w-[5.903vw] max-w-[85px]"
    >
      <div className="flex flex-col items-center justify-center md:gap-2.5-vw gap-2">
        <div className="md:h-[5.903vw] h-[85px] md:w-[5.903vw] w-[85px] rounded-md bg-white flex justify-center items-center">
          <img
            className="md:w-[2.431vw] md:h-[2.431vw]"
            src={icon.icon}
            width="35"
            height="35"
          />
        </div>
        <div>
          <Typography $type="PSmall" className="opacity-40 text-center">
            {icon.helpText}
          </Typography>
        </div>
      </div>
    </div>
  );
};
