import React from 'react';
import { FAQ } from '@/components/FAQ/FAQ';
import { FAQMainPageList } from './FAQMainPageList';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';

type FAQMainPageProps = {};
export const FAQMainPage = ({}: FAQMainPageProps) => {
  const isMdMax = useMediaQuery('(max-width: 768px)');
  return (
    <section className="z-50 bg-white">
      <Container className="md:pt-24-vw pt-14 md:pb-24-vw pb-14 xl:max-w-[74.722vw]">
        <Typography
          as="h3"
          $type="Heading1"
          className="font-formula text-center"
          $weight="700"
        >
          Frequently asked questions
        </Typography>
        <FAQ isMobile={isMdMax} list={FAQMainPageList} />
      </Container>
    </section>
  );
};
