import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { Loader } from '@/components/Loader/Loader';
import { useLoader } from '@/hooks/useLoader';
import type { GetServerSidePropsContext } from 'next';
import { MainPageIcon } from '@/assets/icons/mainPageIcon';
import { MeinSections } from '@/components/PagesSections/MainPageSections/MainSections';
import { RequestDemoMagicSection } from '@/components/PagesSections/MainPageSections/RequestDemoMagicSection/RequestDemoMagicSection';
import { SecondSection } from '@/components/PagesSections/MainPageSections/SecondSection/SecondSection';
import { ImageScrollSectionDesktop } from '@/components/PagesSections/MainPageSections/ImageScrollSection/Desktop/ImageScrollSectionDesktop';
import { ImageScrollSectionMobile } from '@/components/PagesSections/MainPageSections/ImageScrollSection/Mobile/ImageScrollSectionMobile';
import { BusinessNeedsSection } from '@/components/PagesSections/MainPageSections/BusinessNeedsSection/BusinessNeedsSection';
import { GetStartedSection } from '@/components/PagesSections/MainPageSections/GetStartedSection/GetStartedSection';
import { ConnectCossupportBiSection } from '@/components/PagesSections/MainPageSections/ConnectCossupportBiSection/ConnectCossupportBiSection';
import { CossupportEnterpriseSection } from '@/components/PagesSections/MainPageSections/CossupportEnterpriseSection/CossupportEnterpriseSection';
import { PatentSection } from '@/components/PagesSections/MainPageSections/PatentSection/PatentSection';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Script from 'next/script';

interface MainPageProps {
  originUrl: string;
}

export default function MainPage({ originUrl }: MainPageProps) {
  const [videoLoading, setVideoLoading] = useState<boolean>(false);
  const loading = useLoader();
  const mobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setTimeout(() => setVideoLoading(true), 300);
  }, []);

  return (
    <>
      <Head>
        <meta key="utf" charSet="UTF-8" />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta
          key="facebook"
          name="facebook-domain-verification"
          content="5q9rm986matfhco68cm9zmwzh78kw4"
        />
        <title>
          Generative AI for Customer Support: Smarter, Faster, and More
          Efficient
        </title>
        <meta
          key="subject"
          name="subject"
          content="CoSupport AI | an AI Business Analyst for Your Business"
        />
        <meta
          key="description"
          name="description"
          content="Transform your business with generative AI for customer support. CoSupport AI automates responses, boosts agent productivity, and provides valuable business insights"
        />
        <link key="canonical" rel="canonical" href={`${originUrl}/`} />
        <meta
          key="ogtitle"
          property="og:title"
          content="CoSupport AI | an AI Business Analyst for Your Business"
        />
        <meta key="ogtype" property="og:type" content="website" />
        <meta key="oglocale" property="og:locale" content="en_US" />
        <meta property="og:url" content={`${originUrl}/`} />
        <meta
          key="ogurl"
          property="og:image"
          content={`${originUrl}/img/open-graph/main-page.png`}
        />
        <meta
          key="ogsitename"
          property="og:site_name"
          content={`${originUrl}/`}
        />
        <meta
          key="ogdescription"
          property="og:description"
          content="CoSupport AI – the solution that enhances customer support and empowers businesses with data-driven insights. It ensures exceptional customer service and delivers unparalleled Business Intelligence to core departments. CoSupport AI consists of 3 components: Agent, Customer, and BI."
        />
        <link key="animatemin" rel="stylesheet" href="/css/animate.min.css" />
        <link rel="stylesheet" href="/css/style.css" />
        <link rel="stylesheet" href="/css/media.css" />
        <script src="/js/is.js"></script>
        <script src="/js/script.js"></script>
        <script
          key="organization-jsonld"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "CoSupport AI",
            "url": "https://cosupport.ai/",
            "logo": "https://cosupport.ai/img/logo.png",
            "foundingDate": "October 11, 2020",
            "award": "https://cosupport.ai/articles/cosupport-wins-ai-world-series-award-2024",
            "founders": [
          {
            "@type": "Person",
            "name": "Daria Leshchenko",
            "description": "CoAdvisor, CoFounder",
            "sameAs": "https://www.linkedin.com/in/darialeshchenko"
          },
          {
            "@type": "Person",
            "name": "Roman Lutsyshyn",
            "description": "CoFounder, CTO",
            "sameAs": "https://www.linkedin.com/in/roman-lutsushyn"
          } ],
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "5356 Hermitage Ave",
            "addressLocality": "Valley Village",
            "addressRegion": "CA",
            "postalCode": "91607",
            "addressCountry": "US"
          },
            "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer support",
            "email": "contact@cosupports.ai"
          },
            "sameAs": [
            "https://www.linkedin.com/company/98213887",
            "https://www.instagram.com/cosupport.ai/",
            "https://twitter.com/cosupportai",
            "https://www.youtube.com/channel/UC9RNK2lsGajtGYx-cNfFxKw",
            "https://www.facebook.com/CoSupportAI"
            ]}`,
          }}
        />
        <Script
          id="googletagmanager3"
          key="googletagmanager3"
          strategy="beforeInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=AW-11237889467"
        />
        <Script
          id="googletagmanager4"
          key="googletagmanager4"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-11237889467');
          `,
          }}
        />
        <Script
          id="googletagmanager5"
          key="googletagmanager5"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-WXHFTKL9');
          `,
          }}
        />
      </Head>
      <MainPageIcon />
      {loading && <Loader />}
      <Header pathname={`/`} />
      <MeinSections>
        <SecondSection />
        <RequestDemoMagicSection />
      </MeinSections>
      <div className="bg-white z-10">
        {!mobile && videoLoading && (
          <ImageScrollSectionDesktop mobile={mobile} />
        )}
        {mobile && videoLoading && <ImageScrollSectionMobile />}
        <BusinessNeedsSection />
        <GetStartedSection />
        <ConnectCossupportBiSection />
        <CossupportEnterpriseSection />
        <PatentSection />
      </div>
      <Footer />
    </>
  );
}
export const getStaticProps = async (
  context: GetServerSidePropsContext,
): Promise<{ props: MainPageProps }> => {
  const originUrl: string = process.env.ORIGIN_URL || 'https://cosupport.ai';

  return {
    props: { originUrl },
  };
};
