export const ImageScrollCardIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <circle cx="15" cy="15" r="15" fill="#05050D" />
      <path
        d="M12.6162 20.9951L18.6112 15L12.6162 9.00493"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
};
