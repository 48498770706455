import style from './BlackButton.module.css';
import React, { ReactEventHandler, ReactNode } from 'react';
import Link from 'next/link';
import { clsx } from 'clsx';

interface BlackButtonProps {
  url?: string;
  onClick?: ReactEventHandler;
  target?: string;
  className?: string;
  linkBtnClassName?: string;
  form?: string;
  children: ReactNode;
  disabled?: boolean;
}

export const BlackButton = ({
  url,
  target,
  onClick = () => {},
  className = '',
  children,
  form,
  disabled = false,
  linkBtnClassName = '',
}: BlackButtonProps) => {
  return url ? (
    target === '_blank' ? (
      <a href={url} target={target} className={clsx(style.link, className)}>
        <button
          disabled={disabled}
          form={form}
          className={clsx(style.blackButton, linkBtnClassName)}
          onClick={onClick}
        >
          <div className={style.buttonText}>{children}</div>
          <div className={style.gradient}></div>
        </button>
      </a>
    ) : (
      <Link href={url} className={clsx(style.link, className)}>
        <button
          disabled={disabled}
          form={form}
          className={clsx(style.blackButton, linkBtnClassName)}
          onClick={onClick}
        >
          <div className={style.buttonText}>{children}</div>
          <div className={style.gradient}></div>
        </button>
      </Link>
    )
  ) : (
    <button
      disabled={disabled}
      form={form}
      className={clsx(style.blackButton, className)}
      onClick={onClick}
    >
      <div className={style.buttonText}>{children}</div>
      <div className={style.gradient}></div>
    </button>
  );
};
