import React, { ReactNode } from 'react';
import { TextBlock } from './TextBlock';
import { clsx } from 'clsx';
import Image, { StaticImageData } from 'next/image';

type ContentProps = {
  className?: string;
  title: ReactNode | string;
  subtitle?: ReactNode | string;
  bottomContent?: ReactNode | string;
  image: string;
  imageMobile?: string;
  imageClassName?: string;
  imageContainerClassName?: string;
};

export const Slide = ({
  className,
  title = '',
  subtitle = '',
  bottomContent = '',
  image,
  imageMobile,
  imageClassName,
  imageContainerClassName = '',
}: ContentProps) => {
  return (
    <div
      className={clsx(
        'flex lg:gap-24-vw gap-4 md:flex-row flex-col items-center justify-center',
        className,
      )}
    >
      <TextBlock
        className="w-full grow md:max-w-[36.528vw]"
        bottomContent={bottomContent}
        title={title}
        subtitle={subtitle}
      />
      <div
        className={clsx(
          imageContainerClassName,
          'flex justify-center md:w-[32.778vw] md:h-[29.653vw] max-md:max-w-[245px]',
        )}
      >
        <picture
          className={clsx(imageClassName, 'w-full h-full object-contain')}
        >
          <source srcSet={image} media="(min-width: 640px)" />
          <img
            className="h-full w-full object-contain object-bottom"
            src={imageMobile}
            alt={`${title} image`}
          />
        </picture>
      </div>
    </div>
  );
};
