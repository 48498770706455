import React from 'react';
import { StarIcon } from '@/assets/icons/starIcon';
import { Typography } from '@/components/Typography/Typography';
import { MessageIcon } from '@/assets/icons/messageIcon';
import { HeadphonesIcon } from '@/assets/icons/headphonesIcon';

export const ImageScroll = () => {
  return (
    <>
      <div className="image-scroll">
        <img src="/img/image-scroll-mobile.png" />
      </div>
      <div className="image-scroll-horizontal">
        <div className="image-scroll-card overflow-hidden">
          <div className="image-scroll-card-header absolute top-0 w-full left-0 bg-purple max-md:px-5 max-md:py-3 flex items-center max-md:gap-2.5">
            <div className="flex justify-center items-center">
              <MessageIcon className="max-md:w-[17px] max-md:h-[17px]" />
            </div>
            <Typography $type="PLittle" $weight="600" className="uppercase">
              customer service automatisation
            </Typography>
          </div>
          <div className="body-wrapper">
            <div className="body max-md:pt-12">
              <h5>
                <span className="italic extra-bold">
                  Are the number of requests wearing down your agents?
                </span>
              </h5>
              <h3> AI-Powered Response Automation</h3>
              <p>
                Delegate up to 100% of requests to our AI assistants. Decide
                what fraction of requests to address to AI. The rules are yours.
              </p>
            </div>
          </div>
        </div>
        <div className="image-scroll-card overflow-hidden">
          <div className="image-scroll-card-header absolute top-0 w-full left-0 bg-malibu max-md:px-5 max-md:py-3 flex items-center max-md:gap-2.5">
            <div className="flex justify-center items-center">
              <HeadphonesIcon className="max-md:w-[17px] max-md:h-[17px]" />
            </div>
            <Typography $type="PLittle" $weight="600" className="uppercase">
              Support agent assistant
            </Typography>
          </div>
          <div className="body-wrapper">
            <div className="body max-md:pt-12">
              <h5>
                <span className="italic extra-bold">
                  Does your team fail to answer at a break-neck speed?
                </span>
              </h5>
              <h3>Patented Response Acceleration Technology</h3>
              <p>
                Benefit from our AI assistant response speed. CoSupport Agent
                responds in milliseconds while GPT needs 3-5 seconds for
                request.
              </p>
            </div>
          </div>
        </div>
        <div className="image-scroll-card overflow-hidden">
          <div className="image-scroll-card-header absolute top-0 w-full left-0 bg-chartreuse_yellow max-md:px-5 max-md:py-3 flex items-center max-md:gap-2.5">
            <div className="flex justify-center items-center">
              <StarIcon className="max-md:w-[17px] max-md:h-[17px]" />
            </div>
            <Typography $type="PLittle" $weight="600" className="uppercase">
              personal business copilot
            </Typography>
          </div>
          <div className="body-wrapper">
            <div className="body max-md:pt-12">
              <h5>
                <span className="italic extra-bold">
                  Eager to see areas of improvement clearly?
                </span>
              </h5>
              <h3>BI Copilot for the Helicopter View</h3>
              <p>
                Ask AI simple questions and get insights to make decisions on
                complex issues. Ground your strategy on approved insights.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="image-scroll-control">
        <fieldset>
          <input type="radio" name="scroll-control" value="0" defaultChecked />
          <input type="radio" name="scroll-control" value="1" />
          <input type="radio" name="scroll-control" value="2" />
        </fieldset>
      </div>
    </>
  );
};
