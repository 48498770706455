export const StarIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83675 0.614043C8.21528 0.27857 8.78472 0.278569 9.16325 0.614043L10.5451 1.8387C10.7348 2.00678 10.9812 2.09659 11.2345 2.08996L13.0791 2.04172C13.5842 2.02852 14.0197 2.39408 14.0943 2.89376L14.3677 4.72537C14.405 4.97546 14.5357 5.20213 14.7334 5.35974L16.1804 6.51301C16.5748 6.82741 16.6733 7.38638 16.4102 7.81671L15.4436 9.39719C15.3118 9.61275 15.2665 9.87022 15.3168 10.1178L15.6854 11.934C15.7858 12.4287 15.5019 12.9206 15.0233 13.0811L13.2718 13.6684C13.0318 13.7489 12.8312 13.9174 12.7105 14.14L11.8293 15.7657C11.5884 16.2102 11.0535 16.405 10.5831 16.2194L8.86702 15.5423C8.63119 15.4492 8.36881 15.4492 8.13298 15.5423L6.41685 16.2194C5.94651 16.405 5.41164 16.2102 5.17069 15.7657L4.28946 14.14C4.16881 13.9174 3.96825 13.7489 3.72822 13.6684L1.97668 13.0811C1.49813 12.9206 1.21418 12.4287 1.31459 11.934L1.68325 10.1178C1.73351 9.87022 1.68818 9.61275 1.55635 9.39719L0.589823 7.81671C0.326655 7.38638 0.425193 6.82741 0.819649 6.51301L2.26655 5.35974C2.46429 5.20213 2.59497 4.97546 2.6323 4.72537L2.90568 2.89376C2.98026 2.39408 3.41583 2.02852 3.92087 2.04172L5.76548 2.08996C6.01882 2.09659 6.26522 2.00678 6.45488 1.8387L7.83675 0.614043Z"
        fill="#05050D"
      />
      <path
        d="M8.49739 4.49984L9.85817 7.22189L12.5807 8.58317L9.85817 9.94445L8.49739 12.6665L7.13662 9.94445L4.41406 8.58317L7.13662 7.22189L8.49739 4.49984Z"
        fill="#DFF800"
      />
    </svg>
  );
};
