import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { GreenButton } from '@/components/ButtonsCollection/GreenButton/GreenButton';
import { MediaRecognition } from '@/components/MediaRecognition/MediaRecognition';
import { PATH_DEMO } from '@/constants/spa-routes';

export const RequestDemoMagicSection = () => {
  return (
    <div className="bg-white relative">
      <Container className="md:pt-16-vw pt-4 flex justify-center">
        <section className="magic magic-container md:max-w-[59.458vw]">
          <div className="block lg:hidden mb-7">
            <MediaRecognition isDark={false} />
          </div>
          <div>
            <Typography
              as="h2"
              $type="Heading1"
              className="md:max-w-[52vw] font-formula"
            >
              AI for Customer Support & BI 360°
            </Typography>
            <div className="radial-gradient-background top-[45px]"></div>
          </div>
          <div>
            <Typography $type="Heading4" $weight="400">
              Your business holds untapped data: customer support emails, chats,
              product FAQs, blog posts, internal documentation, and more.
              CoSupport™ AI copilot transforms your data into an endless source
              of product and business intelligence. Our AI solution for customer
              support and an internal AI copilot empower organizations with
              contextual information powered by Natural Language Processing
              technology.
            </Typography>
          </div>
          <div className="button-container md:mt-4">
            <GreenButton url={PATH_DEMO.ROOT} className="max-md:w-full">
              <div className="button-text flex justify-center items-center md:gap-4-vw gap-4">
                <Typography
                  $color={COLORS.EBONY}
                  as="span"
                  $type="Heading4"
                  $weight="700"
                >
                  Request CoSupport Demo
                </Typography>
              </div>
            </GreenButton>
          </div>
        </section>
      </Container>
    </div>
  );
};
