import React, { ReactElement } from 'react';
import { HumanComment } from './HumanComment';
import { AiComment } from './AiComment';
import { Typography } from '@/components/Typography/Typography';
import TextWriter from '@/components/TextWriter/TextWriter';
import { COLORS } from '@/constants/colors';

type commentsType = {
  id: number;
  internalId: number;
  comment: ReactElement;
};

export const commentsBusinessAnalyst: commentsType[] = [
  {
    id: 0,
    internalId: 0,
    comment: (
      <HumanComment>
        <TextWriter
          className="absolute top-0"
          type="PMedium"
          duration={1}
          delay={2}
          text={'Count '}
          disableCursor
          as="p"
        >
          <TextWriter
            disableCursor
            duration={1.5}
            delay={3}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            as="span"
            text={'all customers issues in Q2 2023'}
          />
          <TextWriter
            duration={1.5}
            delay={4.5}
            type="PMedium"
            as="span"
            text={' regarding Audio Issues during LiveStream'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          Count <span className="green">all customers issues in Q2 2023</span>{' '}
          regarding Audio Issues during LiveStream
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 1,
    internalId: 1,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          During Q2 2023, there were a total of{' '}
          <b className="dark">749 customer issues</b> related to audio issues
          during livestreams.
        </Typography>
      </AiComment>
    ),
  },
  {
    id: 2,
    internalId: 2,
    comment: (
      <HumanComment>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          color={COLORS.EBONY}
          weight={'600'}
          type="PMedium"
          text={'Name 3 most popular'}
          as="p"
        >
          <TextWriter
            duration={1.5}
            delay={3}
            type="PMedium"
            text={' Audio Issues in Q2 2023'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          <span className="green">Name 3 most popular</span> Audio Issues in Q2
          2023
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 3,
    internalId: 3,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          <b className="dark">Audio delay/synchronization</b> issues.
          <br />
          <b className="dark">Audio cutting</b> out randomly or after a certain
          duration.
          <br />
          <b className="dark">Audio quality</b> issues, pops, or poor sound
          quality.
        </Typography>
      </AiComment>
    ),
  },
  {
    id: 4,
    internalId: 4,
    comment: (
      <HumanComment>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          type="PMedium"
          text={'Identify '}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={1}
            delay={3}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'key opportunities for innovation'}
          />
          <TextWriter
            duration={2}
            delay={4}
            type="PMedium"
            text={
              ' based on user feedback this month. Highlight top 3, without details'
            }
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          Identify{' '}
          <span className="green">key opportunities for innovation</span> based
          on user feedback this month. Highlight top 3, without details
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 5,
    internalId: 5,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          Based on user feedback, here are top 3 opportunities for innovation
        </Typography>
        <ol>
          <div>
            <Typography $type="PMedium">
              <b className="dark">Enhanced User Interface</b> (UI) and User
              Experience (UX).
            </Typography>
          </div>
          <div>
            <Typography $type="PMedium">
              <b className="dark">Integration with Third-Party Platforms.</b>
            </Typography>
          </div>
          <div>
            <Typography $type="PMedium">
              <b className="dark">Advanced Automation</b> and AI-driven
              features.
            </Typography>
          </div>
        </ol>
      </AiComment>
    ),
  },
  {
    id: 6,
    internalId: 6,
    comment: (
      <HumanComment>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          color={COLORS.EBONY}
          weight={'600'}
          type="PMedium"
          text={'More about Advanced Automation'}
          as="p"
        >
          <TextWriter
            duration={1.5}
            delay={3}
            type="PMedium"
            text={' and AI-driven features'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          <span className="green">More about Advanced Automation</span> and
          AI-driven features
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 7,
    internalId: 7,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          Leverage AI capabilities to automate repetitive tasks and assist users
          in optimizing their live streams and recordings.{' '}
          <b className="dark">Try to use automated camera switching,</b>{' '}
          real-time analytics and insights, automated captioning, and video
          editing assistance.
        </Typography>
      </AiComment>
    ),
  },
];

export const commentsExecutiveManager: commentsType[] = [
  {
    id: 0,
    internalId: 0,
    comment: (
      <HumanComment imgSrc={'/img/em-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1.5}
          delay={2}
          color={COLORS.EBONY}
          weight={'600'}
          type="PMedium"
          text={'Provide most common issues'}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={2}
            delay={3.5}
            type="PMedium"
            text={
              ' that could potentially affect customer retention rate negatively in '
            }
          />
          <TextWriter
            duration={0.5}
            delay={5.5}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'Q2, 2023.'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          <span className="green">Provide most common issues</span> that could
          potentially affect customer retention rate negatively in{' '}
          <span className="green">Q2, 2023.</span>
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 1,
    internalId: 1,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          <span className="dark">
            Most common issues based on the previous tickets in Q2, 2023:
          </span>
        </Typography>
        <ul>
          <li>
            <Typography $type="PMedium">Software Performance.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Bandwidth and Streaming.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Audio and Video Quality.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Feature Requests.</Typography>
          </li>
        </ul>
      </AiComment>
    ),
  },
  {
    id: 2,
    internalId: 2,
    comment: (
      <HumanComment imgSrc={'/img/em-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          type="PMedium"
          text={'How about '}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={1.5}
            delay={3}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'our top 3 most requested solutions'}
          />
          <TextWriter
            duration={1.5}
            delay={4.5}
            type="PMedium"
            text={' in the same quarter.'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          How about{' '}
          <span className="green">our top 3 most requested solutions</span> in
          the same quarter.
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 3,
    internalId: 3,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          Here are the <span className="dark">best performing products</span>
        </Typography>
        <ul>
          <li>
            <Typography $type="PMedium">Broadcast Entertainment.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Sound Storytelling.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Online Summit.</Typography>
          </li>
        </ul>
      </AiComment>
    ),
  },
  {
    id: 4,
    internalId: 4,
    comment: (
      <HumanComment imgSrc={'/img/em-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          type="PMedium"
          text={'What are '}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={1.5}
            delay={3}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'5 most frequently used features'}
          />
          <TextWriter
            duration={1}
            delay={4.5}
            type="PMedium"
            text={' of our product?'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          What are{' '}
          <span className="green">5 most frequently used features</span> of our
          product?
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 5,
    internalId: 5,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          Based on ticket analysis, the{' '}
          <span className="dark">
            most frequently used features of the product are:
          </span>
        </Typography>
        <ol>
          <li>
            <Typography $type="PMedium">Live Streaming.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Virtual Camera.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Video Recording.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Graphics Overlay.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Multiple Camera Inputs.</Typography>
          </li>
        </ol>
      </AiComment>
    ),
  },
  {
    id: 6,
    internalId: 6,
    comment: (
      <HumanComment imgSrc={'/img/em-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1.5}
          delay={2}
          type="PMedium"
          text={'Are there any '}
          as="p"
        >
          <TextWriter
            duration={2}
            delay={3.5}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'emerging user needs or trends?'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          Are there any{' '}
          <span className="green">emerging user needs or trends?</span>
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 7,
    internalId: 7,
    comment: (
      <AiComment>
        <ol>
          <li>
            <Typography $type="PMedium">License and Purchase.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              Assistance and Issue Reporting.
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">Live Streaming.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              Graphics and Visual Elements.
            </Typography>
          </li>
        </ol>
      </AiComment>
    ),
  },
];

export const commentsMarketer: commentsType[] = [
  {
    id: 0,
    internalId: 0,
    comment: (
      <HumanComment imgSrc={'/img/marketer-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1.5}
          delay={2}
          color={COLORS.EBONY}
          weight={'600'}
          type="PMedium"
          text={'List top 3 web pages'}
          as="p"
        >
          <TextWriter
            duration={2}
            delay={3.5}
            type="PMedium"
            text={' that generated the most MQL requests last month'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          <span className="green">List top 3 web pages</span> that generated the
          most MQL requests last month
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 1,
    internalId: 1,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          The top 3 web pages that generated{' '}
          <span className="dark">the most MQL requests last month are:</span>
        </Typography>
        <ol>
          <li>
            <Typography $type="PMedium">
              StreamVerse Innovations Home Page: 243 MQL requests.
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              LiveStream Request Page: 85 MQL requests.
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              Issue Report Page: 85 MQL requests.
            </Typography>
          </li>
        </ol>
      </AiComment>
    ),
  },
  {
    id: 2,
    internalId: 2,
    comment: (
      <HumanComment imgSrc={'/img/marketer-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          color={COLORS.EBONY}
          weight={'600'}
          type="PMedium"
          text={'Are you sure'}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={2}
            delay={3}
            type="PMedium"
            text={' that from Issue Report Page we have 85 MQL request? '}
          />
          <TextWriter
            duration={0.8}
            delay={5}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'Check it'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          <span className="green">Are you sure</span> that from Issue Report
          Page we have 85 MQL request? <span className="green">Check it</span>
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 3,
    internalId: 3,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          <span className="dark">
            Issue Report page generated 85 MQL requests.
          </span>{' '}
          Users encountered technical issues or bugs and were submitting reports
          to seek assistance or resolution.
        </Typography>
      </AiComment>
    ),
  },
  {
    id: 4,
    internalId: 4,
    comment: (
      <HumanComment imgSrc={'/img/marketer-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          type="PMedium"
          text={'Which '}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={1.5}
            delay={3}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'pages gained the most visitors'}
          />
          <TextWriter
            duration={1}
            delay={4.5}
            type="PMedium"
            text={' in Q2 2023'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          Which <span className="green">pages gained the most visitors</span> in
          Q2 2023
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 5,
    internalId: 5,
    comment: (
      <AiComment>
        <ol>
          <li>
            <Typography $type="PMedium">
              <span className="dark">Home Page</span> - 18,513 visits, making it
              the most visited page in Q2 2023.
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              <span className="dark">Contact Form Page</span> - 2,509 visits.
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              <span className="dark">Assistance Page</span> - 9,174 visits.
            </Typography>
          </li>
        </ol>
      </AiComment>
    ),
  },
  {
    id: 6,
    internalId: 6,
    comment: (
      <HumanComment imgSrc={'/img/marketer-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1.5}
          delay={2}
          color={COLORS.EBONY}
          weight={'600'}
          type="PMedium"
          text={'Provide 5 issues'}
          as="p"
        >
          <TextWriter
            duration={2.5}
            delay={3.5}
            type="PMedium"
            text={' that our users have encountered in the past month.'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          <span className="green">Provide 5 issues</span> that our users have
          encountered in the past month.
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 7,
    internalId: 7,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          <span className="dark">
            Here are five problems that your users have experienced in the last
            month:
          </span>
        </Typography>
        <ul>
          <li>
            <Typography $type="PMedium">An issue with Trial Period.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              Problems with streaming to Multiple Platforms.
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">Upgrading License.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">Non-profit Pricing.</Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              Adjusting graphics during live-streaming.
            </Typography>
          </li>
        </ul>
      </AiComment>
    ),
  },
  {
    id: 8,
    internalId: 8,
    comment: (
      <HumanComment imgSrc={'/img/marketer-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1.5}
          delay={2}
          color={COLORS.EBONY}
          weight={'600'}
          type="PMedium"
          text={'Generate topics for our blog'}
          as="p"
        >
          <TextWriter
            duration={2.5}
            delay={3.5}
            type="PMedium"
            text={' and social media posts based on these problems.'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          <span className="green">Generate topics for our blog</span> and social
          media posts based on these problems.
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 9,
    internalId: 9,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          Here are some variants with <span>topics for blog post articles</span>
        </Typography>
        <ol>
          <li>
            <Typography $type="PMedium">
              "Extending Your Trial Period: Exploring Options Beyond the Trial"
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              "Unlocking the Power of StreamVerse Innovations: How to Prolong
              Your License for Free"
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              "Broadcasting for Non-Profits: Maximizing Value with Special
              Pricing"
            </Typography>
          </li>
        </ol>
      </AiComment>
    ),
  },
];

export const commentsProductManager: commentsType[] = [
  {
    id: 0,
    internalId: 0,
    comment: (
      <HumanComment imgSrc={'/img/pm-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          type="PMedium"
          text={'Provide the most frequent '}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={1}
            delay={3}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'product-related issues'}
          />
          <TextWriter
            duration={1}
            delay={4}
            type="PMedium"
            text={' that our customers encountered last week.'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          Provide the most frequent{' '}
          <span className="green">product-related issues</span> that our
          customers encountered last week.
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 1,
    internalId: 1,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          <span className="dark">
            The most frequent issues customers faced last week:
          </span>
        </Typography>
        <ul>
          <li>
            <Typography $type="PMedium">
              Flickering output on Zoom meetings.
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              Streaming issues with StreamVerse Innovations at Facebook Live.
            </Typography>
          </li>
          <li>
            <Typography $type="PMedium">
              Audio playback issues on webshow.
            </Typography>
          </li>
        </ul>
      </AiComment>
    ),
  },
  {
    id: 2,
    internalId: 2,
    comment: (
      <HumanComment imgSrc={'/img/pm-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={0.5}
          delay={2}
          color={COLORS.EBONY}
          weight={'600'}
          type="PMedium"
          text={'Compare'}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={1}
            delay={2.5}
            type="PMedium"
            text={' it to our product '}
          />
          <TextWriter
            disableCursor
            duration={0.5}
            delay={3.5}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'FAQ page.'}
          />
          <TextWriter
            duration={1.5}
            delay={4}
            type="PMedium"
            text={' List the ones that are missing.'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          <span className="green">Compare</span> it to our product{' '}
          <span className="green">FAQ page.</span> List the ones that are
          missing.
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 3,
    internalId: 3,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          Missing one is: <span className="dark">Audio playback issues</span> on
          webshow.
        </Typography>
      </AiComment>
    ),
  },
  {
    id: 4,
    internalId: 4,
    comment: (
      <HumanComment imgSrc={'/img/pm-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          type="PMedium"
          text={'Cool! And Could you '}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={1.5}
            delay={3}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={'clarify this problem'}
          />
          <TextWriter
            duration={1.5}
            delay={4.5}
            type="PMedium"
            text={' – Audio playback issues on webshow?'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          Cool! And Could you{' '}
          <span className="green">clarify this problem</span> – Audio playback
          issues on webshow?
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 5,
    internalId: 5,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          Customers have encountered problems with{' '}
          <span className="dark">
            audio playback for videos and interviews on their webshows.
          </span>{' '}
          Previously, they contacted tech support without finding a satisfactory
          solution.
        </Typography>
      </AiComment>
    ),
  },
  {
    id: 6,
    internalId: 6,
    comment: (
      <HumanComment imgSrc={'/img/pm-human-icon.png'}>
        <TextWriter
          className="absolute top-0"
          disableCursor
          duration={1}
          delay={2}
          color={COLORS.EBONY}
          weight={'600'}
          type="PMedium"
          text={'How agents '}
          as="p"
        >
          <TextWriter
            disableCursor
            duration={1.5}
            delay={3}
            type="PMedium"
            text={'usually'}
          />
          <TextWriter
            disableCursor
            duration={0.5}
            delay={4.5}
            color={COLORS.EBONY}
            weight={'600'}
            type="PMedium"
            text={' resolve'}
          />
          <TextWriter
            duration={0.5}
            delay={5}
            type="PMedium"
            text={' this issue?'}
          />
        </TextWriter>
        <Typography $type="PMedium" className="opacity-0">
          <span className="green">How agents </span> usually{' '}
          <span className="green"> resolve </span>
          this issue?
        </Typography>
      </HumanComment>
    ),
  },
  {
    id: 7,
    internalId: 7,
    comment: (
      <AiComment>
        <Typography $type="PMedium">
          They suggest{' '}
          <span className="dark">
            using the Audio Aux in the output destination section
          </span>{' '}
          to listen to the audio during the stream and mute unnecessary
          channels.
        </Typography>
      </AiComment>
    ),
  },
];
