import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import {
  PATH_AGENT,
  PATH_BI,
  PATH_CUSTOMER,
  PATH_INTEGRATION,
} from '@/constants/spa-routes';
import Link from 'next/link';
import { useRouter } from 'next/router';

export const AiForCustomerSupport = () => {
  const { push } = useRouter();

  return (
    <div className="relative z-1 bg-ebony">
      <Container>
        <div className="ai-for-customer-support md:pb-0 md:pt-16-vw bg-[radial-gradient(50%_54.83%_at_50%_45.17%,#0E2D2B_0%,#05050D_100%)] bg-no-repeat">
          <Typography
            as="h2"
            $type="Heading1"
            $color={COLORS.WHITE}
            className="font-formula"
          >
            AI for Customer Support & BI 360°
          </Typography>
          <div className="text">
            <Typography $type="Heading4">
              CoSupport™ AI has the power to redefine the way businesses act.
              From HR to finance, customer experience, sales & marketing,
              analytics, and your business operations. With an internal AI
              copilot, employees could simply ask a question or highlight the
              problem and{' '}
              <span className="bold white">
                receive intelligent answers from the data across your company
                infrastructure.
              </span>
            </Typography>
          </div>
          <div className="logo-wrapper md:pt-12-vw md:pb-2.5-vw pt-8 pb-2">
            <div className="logo-icon-wrapper max-md:w-40 flex justify-center items-center drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
              <img
                className="md:h-40-vw md:w-40-vw"
                alt="ai-for-customer-support"
                src="/img/ai-for-customer-support-logo-white.png"
                width={155}
                height={155}
              />
            </div>
            <div className="text md:mt-5-vw">
              <Typography $type="Heading2">
                <span className="bold">co</span>support™
              </Typography>
            </div>
          </div>
          <div className="arrows">
            <img
              src="/img/ai-for-customer-support-arrow-color.svg"
              width="100%"
            />
          </div>
          <div className="cards">
            <div className="card" onClick={() => push(PATH_CUSTOMER.ROOT)}>
              <div className="title">
                <img
                  className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw]"
                  src="/img/customer-logo-shadow.png"
                />
                <Typography as="h4" $type="Heading4">
                  <span className="extra-bold">co</span>support™{' '}
                  <span className="extra-bold">customer</span>
                  <span className={'arrow'}> →</span>
                </Typography>
              </div>
              <div className="content md:max-w-96-vw">
                <Typography $type="PMedium">
                  Self-sufficient generative AI for customer support that
                  delivers highly accurate responses to your customers all on
                  its own.{' '}
                  <span className="white bold">
                    CoSupport™ Customer saves thousands of man-hours
                  </span>{' '}
                  by handling up to 100% of customers’ requests with its
                  comprehensive AI customer care.
                </Typography>
              </div>
            </div>
            <div className="card" onClick={() => push(PATH_AGENT.ROOT)}>
              <div className="title">
                <img
                  className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw]"
                  src="/img/agent-logo-shadow.png"
                />
                <Typography as="h4" $type="Heading4">
                  <span className="extra-bold">co</span>support™{' '}
                  <span className="extra-bold">agent</span>
                  <span className={'arrow'}> →</span>
                </Typography>
              </div>
              <div className="content md:max-w-96-vw flex flex-col md:gap-2.5-vw gap-2.5">
                <Typography $type="PMedium">
                  AI agent assistant is designed to boost productivity of your
                  human support staff.{' '}
                  <span className="white bold">
                    CoSupport™ Agent optimizes and reduces ticket processing
                    time
                  </span>{' '}
                  by providing reply suggestions, based on context from the
                  entire conversation thread.
                </Typography>
                <Link
                  href={PATH_INTEGRATION.INTEGRATION_ZENDESK}
                  onClick={(event) => {
                    event.stopPropagation();
                    push(PATH_INTEGRATION.INTEGRATION_ZENDESK);
                  }}
                >
                  <Typography
                    as="span"
                    $type="PMedium"
                    $color={COLORS.MALIBU}
                    className="cursor-pointer underline italic"
                  >
                    Explore how the CoSupport Agent can transform your Zendesk
                    experience  →
                  </Typography>
                </Link>
              </div>
            </div>
            <div className="card" onClick={() => push(PATH_BI.ROOT)}>
              <div className="title">
                <img
                  className="md:h-[3.125vw] h-[45px] w-[45px] md:w-[3.125vw]"
                  src="/img/bi-logo-shadow.png"
                />
                <Typography as="h4" $type="Heading4">
                  <span className="extra-bold">co</span>support™{' '}
                  <span className="extra-bold">bi</span>
                  <span className={'arrow'}> →</span>
                </Typography>
              </div>
              <div className="content md:max-w-96-vw">
                <Typography $type="PMedium">
                  Innovative AI assistant for internal use within your
                  organization that{' '}
                  <span className="white bold">
                    offers insights based on context relevant to your products
                    and customers.
                  </span>{' '}
                  To achieve this, we connect to your company’s data sources and
                  it gains real live knowledge.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
