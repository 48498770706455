import React from 'react';
import { AiForCustomerSupport } from './Sections/AiForCustomerSupport';
import { PowerGenAiCopilot } from './Sections/PowerGenAiCopilot';
import { HowSolutionHelp } from './Sections/HowSolutionHelp/HowSolutionHelp';
import { ImageScroll } from './Sections/ImageScroll';
import { clsx } from 'clsx';

type ImageScrollSectionDesktopProps = {
  mobile?: boolean;
};

export const ImageScrollSectionDesktop = ({
  mobile,
}: ImageScrollSectionDesktopProps) => {
  return (
    <div className="bg-white relative">
      <section
        className={clsx(
          !mobile && 'desktop',
          'image-scroll-container container-global img-scroll-section-container md:pt-16-vw',
        )}
      >
        <ImageScroll />
        <AiForCustomerSupport />
        <PowerGenAiCopilot />
        <HowSolutionHelp />
      </section>
    </div>
  );
};
