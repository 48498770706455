import React, { useState } from 'react';
import { Container } from '@/components/Containers/Container';
import { IconsPart } from './IconsPart/IconsPart';
import { ImagePart } from './ImagePart';

export const ConnectCossupportBiSection = () => {
  const [selectedFilter, setSelectedFilter] = useState(0);

  const handleFilterChange = (value: string) => {
    setSelectedFilter(Number(value));
  };

  return (
    <div className="relative bg-white">
      <Container className="md:pt-0 pt-10 md:pb-0 pb-10 max-md:p-0">
        <section className="connect-cosupport-bi max-md:p-0">
          <div className="panel flex max-md:flex-col rounded-none md:rounded-[20px] max-md:gap-2.5">
            <IconsPart
              selectedFilter={selectedFilter}
              handleFilterChange={handleFilterChange}
            />
            <ImagePart selectedFilter={selectedFilter} />
          </div>
        </section>
      </Container>
    </div>
  );
};
