import React from 'react';
import styled from 'styled-components';
import { Slider } from './Slider/Slider';

const SectionContainer = styled.div({
  width: '100%',
  position: 'relative',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

export const SecondSection = () => {
  return (
    <SectionContainer className="z-10">
      <Slider />
    </SectionContainer>
  );
};
