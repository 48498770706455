import { motion, useMotionValue, useTransform, animate } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';
import CursorBlinker from '../CursorBlinker/CursorBlinker';
import {
  Typography,
  TypographyAs,
  TypographyType,
} from '@/components/Typography/Typography';
import { clsx } from 'clsx';

export interface TextWriterProps {
  delay?: number;
  text: string;
  className?: string;
  duration?: number;
  type?: TypographyType;
  color?: string;
  id?: string;
  cursorColor?: string;
  as?: TypographyAs;
  disableCursor?: boolean;
  disableCursorWhileDilay?: boolean;
  weight?: string;
  children?: ReactNode;
}

export default function TextWriter({
  delay = 0,
  text = '',
  className,
  duration = 1,
  color,
  id,
  type = 'PRegular' as TypographyType,
  as = 'span',
  cursorColor,
  weight,
  disableCursor = false,
  children,
  disableCursorWhileDilay = false,
}: TextWriterProps) {
  const [done, setDone] = useState(false);
  const [startUpdate, setStartUpdate] = useState(false);
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const displayText = useTransform(rounded, (latest) => text.slice(0, latest));

  useEffect(() => {
    const controls = animate(count, text.length, {
      type: 'tween',
      delay: delay,
      duration: duration,
      ease: 'easeInOut',
      onUpdate: (e) => {
        if (e) {
          setStartUpdate(true);
        }
      },
      onComplete: () => {
        setDone(true);
      },
    });
    return controls.stop;
  }, []);

  const showCursorCondition = disableCursorWhileDilay ? startUpdate : true;

  return (
    <Typography
      id={id}
      $color={color}
      as={as}
      $weight={weight}
      $type={type}
      className={clsx(className)}
    >
      <motion.span>{displayText}</motion.span>
      {children}
      {!disableCursor && showCursorCondition && (
        <CursorBlinker
          className={done ? 'invisible w-0' : 'visible w-px'}
          cursorColor={cursorColor}
        />
      )}
    </Typography>
  );
}
